<template>
  <div>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>
          Cuentas cerradas
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-data-table
            :headers="headers"
            :items="all"
            :search="search"
            :footer-props="{
              itemsPerPageText: 'Filas por página'
            }"
            :loading="loading"
            :options.sync="options"
            :server-items-length="itemsLength"
            disable-sort
          >
            <template v-slot:item.id="{ item }">
              <v-btn
                v-if="$can('clientes.update')"
                icon
                @click="onSubmitEdit(item.id)"
              >
                <v-icon>mdi-pencil-circle</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.inactivo="{ item }">
              <div>0</div>
            </template>
            <template v-slot:item.razon_social="{ item }">
              <div v-if="item.razon_social">
                {{ item.razon_social }}
              </div>
            </template>
            <template v-slot:no-results>
              <v-alert color="error" icon="mdi-alert">
                No se ha encontrado ningún resultado para "{{ search }}".
              </v-alert>
            </template>
            <template v-slot:no-data>
              <v-alert color="warning" icon="mdi-alert">
                No hay información de clientes para mostrar.
              </v-alert>
            </template>
            <template v-slot:pageText="props">
              Filas {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import DateInput from '@/components/DateInput'
export default {
  components: {
    'my-date-picker': DateInput,
  },
  data () {
    return {
      search: '',
      selected: [],
      showFilters: false,
      showOrdering: false,
      showExports: false,
    }
  },
  beforeMount () {
    // Agrega el event listener en el gancho beforeMount
    window.addEventListener('keydown', this.handleKeyboard);
  },
  beforeDestroy () {
    // Remueve el event listener en el gancho beforeDestroy
    window.removeEventListener('keydown', this.handleKeyboard);
  },
  created () {
    // this.onFetch()
  },
  computed: {
    ...mapGetters('CuentasCerradas', ['all', 'headers', 'loading', 'itemsLength', 'options', 'filters']),
    options: {
      get () {
        return this.optionsGetter
      },
      set (value) {
        this.setOptions(value)
        this.onFetch()
      }
    },
    searchText: {
      get () {
        return this.filters.searchText
      },
      set (value) {
        this.setFilters({
          ...this.filters,
          searchText: value
        })
      }
    },
    anySelectedFilters (value) {
      const obj = this.filters
      // Itera sobre las claves del objeto
      for (const key in obj) {
        // Verifica si el valor es distinto de null e undefined
        if (obj[key] !== null && obj[key] !== undefined) {
          // Si encuentra un valor que no es null ni undefined, retorna false
          return false
        }
      }
      // Si todos los valores son null o undefined, retorna true
      return true
    }
  },
  methods: {
    ...mapActions('CuentasCerradas', ['onFetch', 'onClearFilters']),
    ...mapMutations('CuentasCerradas', ['setFilters', 'setOptions', 'setEdited', 'setSelected']),
    onClear () {
      this.setFilters({
        ...this.filters,
        searchText: null
      })
      this.onFetch()
    },
    onSubmitEdit (clienteId) {
      this.$router.push({ name: 'clientes-form', params: { id: clienteId }})
    },
    handleKeyboard(event) {
      if(event.key === "F1" && !event.ctrlKey) {
        event.preventDefault()
        this.$router.push({ name: 'comodatos-form' })
      }
      else if(event.key === "F2" && !event.ctrlKey) {
        event.preventDefault()
        this.$router.push({ name: 'clientes-form' })
      }
      else if(event.key === "F3" && !event.ctrlKey) {
        event.preventDefault()
        this.showFilters = !this.showFilters
      }
      else if(event.key === "F4" && !event.ctrlKey) {
        event.preventDefault()
        this.showOrdering = !this.showOrdering
      }
      else if(event.key === "F5" && !event.ctrlKey) {
        event.preventDefault()
        this.onFetch()
      }
      // event.preventDefault()
    }
  }
}
</script>
