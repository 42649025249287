<template>
  <div>
    <v-subheader>Servicios</v-subheader>
    <v-chip-group
      v-model="selected"
      column
      multiple
    >
      <v-chip
        v-for="(item, index) in all"
        :key="index"
        filter
        outlined
      >
        {{ item.nombre }}
      </v-chip>
    </v-chip-group>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  name: 'ServiciosSelectMultipleComponent',
  components: {
    //
  },
  data () {
    return {
      loading: false,
      searchText: null,
      select: null,
      selected: [0, 1],
      all: [
        {
          id: 1,
          nombre: 'CARGO GAS SUBSIDIADO',
          precio: 1.0,
        },
        {
          id: 2,
          nombre: 'CARGO GAS PLANTA',
          precio: 1.0,
        }
      ]
    }
  },
  beforeMount () {
    // Agrega el event listener en el gancho beforeMount
    window.addEventListener('keydown', this.handleKeyboard);
  },
  beforeDestroy () {
    // Remueve el event listener en el gancho beforeDestroy
    window.removeEventListener('keydown', this.handleKeyboard);
  },
  created () {
    // if (this.$route.params.id) {
    //   this.$store.dispatch('Clientes/onFind', this.$route.params.id);
    // }
  },
  computed: {
    // edited: {
    //   get () {
    //     return this.$store.getters['Clientes/edited']
    //   },
    //   set (value) {
    //     this.$store.commit('Clientes/edited', value);
    //   }
    // },
    // domicilioEdited: {
    //   get () {
    //     return this.$store.getters['Domicilios/edited']
    //   },
    //   set (value) {
    //     this.$store.commit('Domicilios/edited', value);
    //   }
    // },
    // isSelected () {
    //   return this.edited.id > 0
    // },
    // clienteId () {
    //   return this.edited.id
    // }
  },
  methods: {
    handleKeyboard(event) {
      if(event.key === "F8" && !event.ctrlKey) {
        //
      }
    },
    querySelections (v) {
      this.loading = true
      // Simulated ajax query
      setTimeout(() => {
        this.items = this.cuentas.filter(e => {
          return (e || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1
        })
        this.loading = false
      }, 500)
    }
  },
  watch: {
    //
  },
}
</script>
