var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("\n        Cuentas cerradas\n      ")]),_vm._v(" "),_c('v-expansion-panel-content',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.all,"search":_vm.search,"footer-props":{
            itemsPerPageText: 'Filas por página'
          },"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.itemsLength,"disable-sort":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
          var item = ref.item;
return [(_vm.$can('clientes.update'))?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.onSubmitEdit(item.id)}}},[_c('v-icon',[_vm._v("mdi-pencil-circle")])],1):_vm._e()]}},{key:"item.inactivo",fn:function(ref){
          var item = ref.item;
return [_c('div',[_vm._v("0")])]}},{key:"item.razon_social",fn:function(ref){
          var item = ref.item;
return [(item.razon_social)?_c('div',[_vm._v("\n              "+_vm._s(item.razon_social)+"\n            ")]):_vm._e()]}},{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"color":"error","icon":"mdi-alert"}},[_vm._v("\n              No se ha encontrado ningún resultado para \""+_vm._s(_vm.search)+"\".\n            ")])]},proxy:true},{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"color":"warning","icon":"mdi-alert"}},[_vm._v("\n              No hay información de clientes para mostrar.\n            ")])]},proxy:true},{key:"pageText",fn:function(props){return [_vm._v("\n            Filas "+_vm._s(props.pageStart)+" - "+_vm._s(props.pageStop)+" de "+_vm._s(props.itemsLength)+"\n          ")]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }