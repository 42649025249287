<template>
  <div>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>
          Filtros
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="numero"
                label="Numero factura"
                type="number"
                clearable
                @click:clear="onClearNumero"
                @keydown.enter="onAplicarFiltros"
                prepend-icon="mdi-card-account-details"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="cliente"
                label="Razón social cliente"
                clearable
                @click:clear="onClearRazonSocial"
                @keydown.enter="onAplicarFiltros"
                prepend-icon="mdi-text"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="emisionFrom"
                label="Fecha desde"
                clearable
                @click:clear="onClearFechaFrom"
                @keydown.enter="onAplicarFiltros"
                prepend-icon="mdi-text"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="emisionTo"
                label="Fecha hasta"
                clearable
                @click:clear="onClearFechaTo"
                @keydown.enter="onAplicarFiltros"
                prepend-icon="mdi-text"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="cae"
                label="CAE"
                clearable
                @click:clear="onClearCae"
                @keydown.enter="onAplicarFiltros"
                prepend-icon="mdi-text"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="caeVencimiento"
                label="CAE Vencimiento"
                clearable
                @click:clear="onClearCaeVencimiento"
                @keydown.enter="onAplicarFiltros"
                prepend-icon="mdi-text"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-footer>
            <v-btn
              color="primary"
              :disabled="!filterable"
              @click="onAplicarFiltros"
              :loading="loading"
            >
              Aplicar filtros
            </v-btn>
            <v-spacer></v-spacer>
            
            <v-btn
              color="primary"
              :disabled="!filterable"
              @click="onQuitarFiltros"
              :loading="loading"
            >
              Quitar filtros
            </v-btn>
          </v-footer>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  data () {
    return {
      //
    }
  },
  beforeMount () {
    // Agrega el event listener en el gancho beforeMount
    // window.addEventListener('keydown', this.handleKeyboard);
  },
  beforeDestroy () {
    // Remueve el event listener en el gancho beforeDestroy
    // window.removeEventListener('keydown', this.handleKeyboard);
  },
  created () {
    //
  },
  computed: {
    ...mapGetters('FacturasEmitidas', ['loading', 'itemsLength', 'filters', 'options', 'sort', 'filterable']),
    sortBy: {
      get () {
        return this.sort.by
      },
      set (value) {
        this.setSortBy(value)
        this.onFetch()
      }
    },
    sortDesc: {
      get () {
        return this.sort.desc
      },
      set (value) {
        this.setSortDesc(value)
        this.onFetch()
      }
    },
    numero: {
      get () {
        return this.filters.numero
      },
      set (value) {
        this.setFilters({
          ...this.filters,
          numero: value
        })
      }
    },
    cliente: {
      get () {
        return this.filters.cliente
      },
      set (value) {
        this.setFilters({
          ...this.filters,
          cliente: value
        })
      }
    },
    emisionFrom: {
      get () {
        return this.filters.emision_from
      },
      set (value) {
        this.setFilters({
          ...this.filters,
          emision_from: value
        })
      }
    },
    emisionTo: {
      get () {
        return this.filters.emision_to
      },
      set (value) {
        this.setFilters({
          ...this.filters,
          emision_to: value
        })
      }
    },
    cae: {
      get () {
        return this.filters.cae
      },
      set (value) {
        this.setFilters({
          ...this.filters,
          cae: value
        })
      }
    },
    caeVencimiento: {
      get () {
        return this.filters.cae_vencimiento
      },
      set (value) {
        this.setFilters({
          ...this.filters,
          cae_vencimiento: value
        })
      }
    },
  },
  methods: {
    ...mapActions('FacturasEmitidas', ['onClearFilters', 'onFetch']),
    ...mapMutations('FacturasEmitidas', ['setFilters', 'setSortBy', 'setSortDesc']),
    handleKeyboard(event) {
      // if(event.key === "Enter" && !event.ctrlKey) {
      //   this.onAplicarFiltros(event)
      // }
    },
    onAplicarFiltros (event) {
      //
      event.preventDefault()
      this.onFetch()
    },
    onQuitarFiltros (event) {
      //
      this.onClearFilters()
      event.preventDefault()
      this.onFetch()
    },
    onClearNumero (event) {
      event.preventDefault()
      this.numero = null
      this.onFetch()
    },
    onClearRazonSocial (event) {
      event.preventDefault()
      this.cliente = null
      this.onFetch()
    },
    onClearFechaFrom (event) {
      event.preventDefault()
      this.emisionFrom = null
      this.onFetch()
    },
    onClearFechaTo (event) {
      event.preventDefault()
      this.emisionTo = null
      this.onFetch()
    },
    onClearCae (event) {
      event.preventDefault()
      this.cae = null
      this.onFetch()
    },
    onClearCaeVencimiento (event) {
      event.preventDefault()
      this.caeVencimiento = null
      this.onFetch()
    },
  }
}
</script>
