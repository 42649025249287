const URL = '/transacciones';

const state = {
  all: [],
  selected: {
    id: null,
    fecha: null,
    importe: null,
    saldo: null,
    transaccion_tipo: null, // debito o credito
    transaccion_detalle: null, // Nombre del servicio o forma de pago
    cuenta_id: null,
    // TRANSACCION KILO
    kilos_cantidad: null,//decimal(15,3)
    kilos_precio: null,//decimal(20,2)
    kilos_saldo: null,//decimal(15,3)
    created_at: null,
    updated_at: null,
  },
  edited: {
    id: null,
    transaccion_tipo: null, // debito o credito
    transaccion_detalle: null, // Nombre del servicio o forma de pago
    fecha: null,
    importe: null,
    saldo: null,
    cuenta_id: null,
    // TRANSACCION KILO
    kilos_cantidad: null,//decimal(15,3)
    kilos_precio: null,//decimal(20,2)
    kilos_saldo: null,//decimal(15,3)
    created_at: null,
    updated_at: null,
  },
  default: {
    id: null,
    fecha: null,
    importe: null,
    saldo: null,
    transaccion_tipo: null, // debito o credito
    transaccion_detalle: null, // Nombre del servicio o forma de pago
    cuenta_id: null,
    // TRANSACCION KILO
    kilos_cantidad: null,//decimal(15,3)
    kilos_precio: null,//decimal(20,2)
    kilos_saldo: null,//decimal(15,3)
    created_at: null,
    updated_at: null,
  },
  filters: {
    fecha_from: null,
    fecha_to: null,
    debito: null,
    credito: null,
    servicio_id: null,
    forma_pago_id: null,
    cuenta_id: null,
  },
  loading: false,
  headers: [
    {
      text: '',
      value: 'id',
      align: 'center'
    },
    {
      text: 'Fecha',
      value: 'fecha',
      align: 'center'
    },
    {
      text: 'Detalle',
      value: 'transaccion_detalle',
      align: 'center'
    },
    {
      text: 'Kilos',
      value: 'kilos_cantidad',
      align: 'center'
    },
    {
      text: 'Precio x kg',
      value: 'kilos_precio',
      align: 'center'
    },
    {
      text: 'Importe',
      value: 'importe',
      align: 'center'
    },
    {
      text: 'Saldo',
      value: 'saldo',
      align: 'center'
    },
  ],
  rules: {
    numero: [
      v => !!v || 'Este campo es necesario',
    ],
    cliente_id: [
      v => !!v || 'Este campo es necesario',
      v => v > 0 || 'Debe existir un cliente asociado',
    ],
    domicilio_id: [
      v => !!v || 'Este campo es necesario',
      v => v > 0 || 'Debe existir un domicilio asociado',
    ],
    firmado: [
      // v => !!v || 'Este campo es necesario',
    ],
    vencimiento: [
      // v => !!v || 'Este campo es necesario',
    ],
  },
  options: {
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
    sortDesc: []
  },
  itemsLength: null,
}

// GETTERS
const getters = {
  all (state) {
    return state.all
  },
  edited (state) {
    return state.edited
  },
  selected (state) {
    return state.selected
  },
  filters (state) {
    return state.filters
  },
  rules (state) {
    return state.rules
  },
  headers (state) {
    return state.headers
  },
  itemsLength (state) {
    return state.itemsLength
  },
  options (state) {
    return state.options
  },
  loading (state) {
    return state.loading
  },
  updatable (state) {
    return state.edited.id == state.selected.id && (
      state.edited.numero != state.selected.numero ||
      state.edited.firmado != state.selected.firmado ||
      state.edited.vencimiento != state.selected.vencimiento ||
      (state.edited.cliente_id && state.edited.cliente_id != state.selected.cliente_id) ||
      (state.edited.domicilio_id && state.edited.domicilio_id != state.selected.domicilio_id)
    )
  },
}

// ACTIONS
const actions = {
  onClearAll ({ commit }) {
    commit('setAll', [])
    commit('setOptions', {
      itemsPerPage: 10,
      page: 1,
      sortBy: [],
      sortDesc: []
    })
    commit('setItemsLength', null)
  },
  onClearEdited ({ state, commit}) {
    commit('setEdited', state.default)
  },
  onClearSelected ({ state, commit}) {
    commit('setSelected', state.default)
  },
  onFetch ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.get(URL, {
        params: {
          itemsPerPage: state.options.itemsPerPage,
          page: state.options.page,
          sortBy: state.options.sortBy[0],
          sortDesc: state.options.sortDesc[0] ? 1 : 0,
          fecha_from: state.filters.fecha_from,
          fecha_to: state.filters.fecha_to,
          cuenta_id: state.filters.cuenta_id
        }
      })
        .then(response => {
          commit('setAll', response.data.data)
          commit('setItemsLength', response.data.meta.total)
          if (state.options.page + state.options.itemsPerPage > response.data.meta.total)
            commit('setPage', 1)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onFind ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.get(URL + '/' + state.filters.comodato_id)
        .then(response => {
          const aux = {
            id: response.data.id,
            numero: response.data.numero,
            firmado: response.data.firmado,
            vencimiento: response.data.vencimiento,
            domicilio_id: response.data.domicilio_id,
            cliente_id: response.data.cliente_id,
          }
          commit('setEdited', aux)
          commit('setSelected', aux)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onSave ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.post(URL, state.edited)
        .then(response => {
          const aux = {
            id: response.data.data.id,
            numero: response.data.data.numero,
            firmado: response.data.data.firmado,
            vencimiento: response.data.data.vencimiento,
            domicilio_id: response.data.data.domicilio_id,
            cliente_id: response.data.data.cliente_id,
            domicilio: response.data.data.domicilio
          }
          commit('setEdited', aux)
          commit('setSelected', aux)
          commit('ResponseHTTP/addSuccess', 'Se ha guardado el comodato', { root: true })
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error al guardar el comodato', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onUpdate ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.put(URL + '/' + state.edited.id, state.edited)
        .then(response => {
          commit('setSelected', state.edited)
          commit('ResponseHTTP/addSuccess', 'Se ha guardado el comodato', { root: true })
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error al guardar el comodato', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onDelete ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.delete(URL + '/' + state.edited.id)
        .then(response => {
          commit('ResponseHTTP/addSuccess', 'Se ha borrado el comodato', { root: true })
          commit('setEdited', state.default)
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error al borrar el comodato', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
}

// MUTATIONS
const mutations = {
  setAll (state, payload) {
    state.all = payload.slice()
  },
  setEdited (state, payload) {
    state.edited = Object.assign({}, payload)
  },
  setSelected (state, payload) {
    state.selected = Object.assign({}, payload)
  },
  setFilters (state, payload) {
    state.filters = Object.assign({}, payload)
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setPage (state, payload) {
    state.options.page = payload
  },
  setItemsPerPage (state, payload) {
    state.options.itemsPerPage = payload
  },
  setItemsLength (state, payload) {
    state.itemsLength = payload
  },
  setOptions (state, payload) {
    state.options = payload
  },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
