const URL = '/formas_pagos';

const state = {
  all: [],
  selected: {
    id: null,
    nombre: null,
    precio: null,
    created_at: null,
    updated_at: null,
  },
  edited: {
    id: null,
    nombre: null,
    precio: null,
    created_at: null,
    updated_at: null,
  },
  default: {
    id: null,
    nombre: null,
    precio: null,
    created_at: null,
    updated_at: null,
  },
  filters: {
    nombre: null,
    forma_pago_id: null,
  },
  loading: false,
  headers: [
    {
      text: 'Nombre',
      value: 'nombre',
      align: 'center'
    },
    {
      text: 'Precio',
      value: 'precio',
      align: 'center'
    },
  ],
  rules: {
    nombre: [
      v => !!v || 'Este campo es necesario',
    ],
    precio: [
      v => !!v || 'Este campo es necesario',
      v => v > 0 || 'Debe ser mayor a cero (0)',
    ],
  },
  options: {},
  itemsLength: null,
}

// GETTERS
const getters = {
  all (state) {
    return state.all
  },
  edited (state) {
    return state.edited
  },
  selected (state) {
    return state.selected
  },
  filters (state) {
    return state.filters
  },
  rules (state) {
    return state.rules
  },
  headers (state) {
    return state.headers
  },
  itemsLength (state) {
    return state.itemsLength
  },
  options (state) {
    return state.options
  },
  loading (state) {
    return state.loading
  },
  updatable (state) {
    return state.edited.id == state.selected.id && (
      state.edited.nombre != state.selected.nombre ||
      state.edited.precio != state.selected.precio
    )
  },
}

// ACTIONS
const actions = {
  onClearAll ({ commit }) {
    commit('setAll', [])
  },
  onClearEdited ({ state, commit}) {
    commit('setEdited', state.default)
  },
  onClearSelected ({ state, commit}) {
    commit('setSelected', state.default)
  },
  onFetch ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.get(URL, {
        params: {
          itemsPerPage: state.options.itemsPerPage,
          page: state.options.page,
          sortBy: state.options.sortBy[0],
          sortDesc: state.options.sortDesc[0] ? 1 : 0,
          nombre: state.filters.nombre,
        }
      })
        .then(response => {
          commit('setAll', response.data.data)
          commit('setItemsLength', response.data.meta.total)
          if (state.options.page + state.options.itemsPerPage > response.data.meta.total)
            commit('setPage', 1)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onFind ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.get(URL + '/' + state.filters.forma_pago_id)
        .then(response => {
          const aux = {
            id: response.data.id,
            nombre: response.data.nombre,
            precio: response.data.precio,
            created_at: response.data.created_at,
            updated_at: response.data.updated_at,
          }
          commit('setEdited', aux)
          commit('setSelected', aux)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onSave ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.post(URL, state.edited)
        .then(response => {
          const aux = {
            id: response.data.data.id,
            nombre: response.data.data.nombre,
            precio: response.data.data.precio,
            created_at: response.data.data.created_at,
            updated_at: response.data.data.updated_at
          }
          commit('setEdited', aux)
          commit('setSelected', aux)
          commit('ResponseHTTP/addSuccess', 'La forma de pago fue registrada.', { root: true })
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error. No se registro la forma de pago', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onUpdate ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.put(URL + '/' + state.edited.id, state.edited)
        .then(response => {
          commit('setSelected', state.edited)
          commit('ResponseHTTP/addSuccess', 'La forma de pago fue actualizada.', { root: true })
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error. No se actualizo la forma de pago.', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onDelete ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.delete(URL + '/' + state.edited.id)
        .then(response => {
          commit('ResponseHTTP/addSuccess', 'La forma de pago fue borrada.', { root: true })
          commit('setEdited', state.default)
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error. No se borro la forma de pago.', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
}

// MUTATIONS
const mutations = {
  setAll (state, payload) {
    state.all = payload.slice()
  },
  setEdited (state, payload) {
    state.edited = Object.assign({}, payload)
  },
  setSelected (state, payload) {
    state.selected = Object.assign({}, payload)
  },
  setFilters (state, payload) {
    state.filters = Object.assign({}, payload)
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setPage (state, payload) {
    state.options.page = payload
  },
  setItemsPerPage (state, payload) {
    state.options.itemsPerPage = payload
  },
  setItemsLength (state, payload) {
    state.itemsLength = payload
  },
  setOptions (state, payload) {
    state.options = payload
  },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
