<template>
  <div>
    <!-- CUENTA VIEW/EDIT -->
    <cuenta-navigation-drawer />
    <!-- CUENTA SELECTOR -->
    <cuentas-select
      v-if="defaultable"
    ></cuentas-select>
    <cuentas-selected
      v-else
    ></cuentas-selected>
    <v-divider class="py-1"></v-divider>
    <!--
      * menu buscar / menu cambiar
      * razon social
      * menu usuario
    -->

    <div v-if="cuentaSelected">
      <!-- FILTROS -->
      <transacciones-filtros />
      <v-divider></v-divider>
      <!-- TRANSACCIONES -->
      <transacciones-datatable />
      <v-divider></v-divider>
      <!-- TAREAS -->
      <tareas-datatable />
    </div>
    <div v-else>
      <v-skeleton-loader
        v-bind="attrs"
        type="table"
      ></v-skeleton-loader>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import NavigationComponent from '@/pages/cuentas/NavigationComponent'
import TransaccionesDatatable from '@/pages/transacciones/Datatable'
import TransaccionesFilters from '@/pages/transacciones/Filters'
import CuentasSelect from '@/pages/cuentas/SelectComponent'
import CuentasSelected from '@/pages/cuentas/SelectedComponent'
import TareasDatatable from '@/pages/tareas/Datatable'

export default {
  components: {
    'cuenta-navigation-drawer': NavigationComponent,
    'cuentas-select': CuentasSelect,
    'cuentas-selected': CuentasSelected,
    'transacciones-datatable': TransaccionesDatatable,
    'transacciones-filtros': TransaccionesFilters,
    'tareas-datatable': TareasDatatable,
  },
  data () {
    return {
      valid: true,
      attrs: {
        boilerplate: true,
        elevation: 2,
      },
    }
  },
  beforeDestroy () {
    //
  },
  created () {
    // if (this.$route.params.id) {
    //   this.$store.dispatch('Clientes/onFind', this.$route.params.id);
    // }
  },
  computed: {
    ...mapGetters('Cuentas', ['selected', 'defaultable']),
    cuentaSelected () {
      return this.selected && this.selected.id > 0
    },
    edited: {
      get () {
        return this.$store.getters['Clientes/edited']
      },
      set (value) {
        this.$store.commit('Clientes/edited', value);
      }
    },
    domicilioEdited: {
      get () {
        return this.$store.getters['Domicilios/edited']
      },
      set (value) {
        this.$store.commit('Domicilios/edited', value);
      }
    },
    isSelected () {
      return this.edited.id > 0
    },
    clienteId () {
      return this.edited.id
    }
  },
  watch: {
    //
  },
  methods: {
    //
  },
}
</script>
