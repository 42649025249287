<template>
  <div>
    <servicios-datatable>
    </servicios-datatable>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import DateInput from '@/components/DateInput'
import ServiciosDatatable from '@/pages/servicios/Datatable'
export default {
  components: {
    'my-date-picker': DateInput,
    'servicios-datatable': ServiciosDatatable
  },
  data () {
    return {
      //
    }
  },
  beforeMount () {
    // Agrega el event listener en el gancho beforeMount
    // window.addEventListener('keydown', this.handleKeyboard);
  },
  beforeDestroy () {
    // Remueve el event listener en el gancho beforeDestroy
    // window.removeEventListener('keydown', this.handleKeyboard);
  },
  created () {
    // this.onFetch()
  },
  computed: {
    //
  },
  methods: {
    //
  }
}
</script>
