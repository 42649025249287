const URL = '/comodatos_instalaciones';

const state = {
  all: [],
  found: [],
  selected: {
    id: null,
    realizado: null,
    orden: null,
    comodato_id: null,
    tipo_instalacion_id: null,
  },
  filters: {
    searchText: null,
    orden: null,
    comodato_id: null,
    tipo_instalacion_id: null,
    first: true,
    realizado: {
      from: null,
      to: null
    },
  },
  edited: {
    id: null,
    realizado: null,
    orden: null,
    comodato_id: null,
    tipo_instalacion_id: null,
  },
  loading: false,
  headers: [
    {
      text: 'Realizado',
      value: 'realizado',
      align: 'center'
    },
    {
      text: 'Orden',
      value: 'orden',
      align: 'center'
    },
    {
      text: 'Comodato',
      value: 'comodato_id',
      align: 'center'
    },
    {
      text: 'Tipo Instalación',
      value: 'tipo_instalacion_id',
      align: 'center'
    },
  ],
  validations: {
    realizado: [
      // v => !!v || 'Este campo es necesario',
    ],
    orden: [
      // v => !!v || 'Este campo es necesario',
    ],
    tipo_instalacion_id: [
      v => !!v || 'Este campo es necesario',
      v => v > 0  || 'Debe existir un tipo de instalación asociado',
    ],
    comodato_id: [
      v => !!v || 'Este campo es necesario',
      v => v > 0 || 'Debe existir un comodato asociado',
    ],
  },
  options: {
    sortBy: ['created_at'],
    sortDesc: [1]
  },
  itemsLength: null,
}

// GETTERS
const getters = {
  all (state) {
    return state.all
  },
  anySelectedFilters (state) {
    return state.filters.numero || 
          state.filters.cliente ||
          state.filters.domicilio ||
          state.filters.tipo_instalacion_id ||
          state.filters.firmado.from ||
          state.filters.firmado.to ||
          state.filters.vencimiento.from ||
          state.filters.vencimiento.to || 
          state.filters.zona_id ? true : false;
  },
  found (state) {
    return state.found
  },
  edited (state) {
    return state.edited
  },
  filters (state) {
    return state.filters
  },
  selected (state) {
    return state.selected
  },
  rules (state) {
    return state.validations
  },
  headers (state) {
    return state.headers
  },
  itemsLength (state) {
    return state.itemsLength
  },
  options (state) {
    return state.options
  },
  loading (state) {
    return state.loading
  },
}

// ACTIONS
const actions = {
  onClearEdited ({ state, commit }) {
    commit('setEdited', state.default)
  },
  onClearSelected ({ state, commit }) {
    commit('setSelected', state.default)
  },
  onFetch ({ state, commit, dispatch }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.get(URL, {
        params: {
          comodato_id: state.filters.comodato_id,
        }
      })
        .then(response => {
          if (response.data.data) {
            const aux = {
              id: response.data.data.id,
              realizado: response.data.data.realizado,
              orden: response.data.data.orden,
              comodato_id: response.data.data.comodato_id,
              tipo_instalacion_id: response.data.data.tipo_instalacion_id,
            }
            commit('setEdited', aux)
            commit('setSelected', aux)
          }
          else {
            dispatch('onClearEdited')
            dispatch('onClearSelected')
          }
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onFind ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.get(URL + '/' + state.filters.comodato_id)
        .then(response => {
          commit('setEdited', response.data)
          commit('setSelected', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onSave ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.post(URL, state.edited)
        .then(response => {
          const aux = {
            id: response.data.data.id,
            realizado: response.data.data.realizado,
            orden: response.data.data.orden,
            comodato_id: response.data.data.comodato_id,
            tipo_instalacion_id: response.data.data.tipo_instalacion_id,
          }
          commit('setEdited', aux)
          commit('setSelected', aux)
          commit('ResponseHTTP/addSuccess', 'Se ha guardado el comodato', { root: true })
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error al guardar el comodato', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onUpdate ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.put(URL + '/' + state.edited.id, state.edited)
        .then(response => {
          commit('setSelected', state.edited)
          commit('ResponseHTTP/addSuccess', 'Se ha guardado el comodato', { root: true })
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error al guardar el comodato', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onDelete ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.delete(URL + '/' + state.edited.id)
        .then(response => {
          commit('ResponseHTTP/addSuccess', 'Se ha borrado el comodato', { root: true })
          commit('setEdited', state.default)
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error al borrar el comodato', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
}

// MUTATIONS
const mutations = {
  setAll (state, payload) {
    state.all = payload.slice()
  },
  setFound (state, payload) {
    state.found = payload
  },
  setEdited (state, payload) {
    state.edited = Object.assign({}, payload)
  },
  setSelected (state, payload) {
    state.selected = Object.assign({}, payload)
  },
  setFilters (state, payload) {
    state.filters = Object.assign({}, payload)
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setEditedId (state, payload) {
    state.edited.id = payload
  },
  setEditedOrden (state, payload) {
    state.edited.orden = payload
  },
  setEditedRealizado (state, payload) {
    state.edited.realizado = payload
  },
  setEditedComodatoId (state, payload) {
    state.edited.comodato_id = payload
  },
  setEditedTipoInstalacionId (state, payload) {
    state.edited.tipo_instalacion_id = payload
  },
  setSelectedId (state, payload) {
    state.selected.id = payload
  },
  setSelectedOrden (state, payload) {
    state.selected.orden = payload
  },
  setSelectedRealizado (state, payload) {
    state.selected.realizado = payload
  },
  setSelectedComodatoId (state, payload) {
    state.selected.comodato_id = payload
  },
  setSelectedTipoInstalacionId (state, payload) {
    state.selected.tipo_instalacion_id = payload
  },
  setPage (state, payload) {
    state.options.page = payload
  },
  setItemsPerPage (state, payload) {
    state.options.itemsPerPage = payload
  },
  setItemsLength (state, payload) {
    state.itemsLength = payload
  },
  setOptions (state, payload) {
    state.options = payload
  },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
