<template>
  <div>
    <v-toolbar
      dark
      color="primary"
    >
      <v-btn
        icon
        @click="onMenuBuscar"
      >
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <v-autocomplete
        v-model="select"
        :loading="loading"
        :items="cuentas"
        item-value="id"
        :search-input="searchText"
        :item-text="(item) => { return item.cliente.razon_social + ' | ' + item.cliente.documento }"
        cache-items
        class="mx-4"
        flat
        hide-no-data
        hide-details
        label="Indique razón social, documento o número de cuenta. Presione enter"
        solo-inverted
        @keydown.enter="onMenuBuscar"
        @change="onSelect"
      >
        <template v-slot:item="data">
          <v-list-item-content>
            <v-list-item-subtitle><v-icon small left>mdi-card-account-details</v-icon>{{ data.item.cliente.documento }}</v-list-item-subtitle>
            <v-list-item-title>{{ data.item.cliente.razon_social }}</v-list-item-title>
            <v-list-item-subtitle><v-icon small left>mdi-credit-card</v-icon>{{ data.item.numero }}</v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </v-toolbar>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  components: {
    //
  },
  data () {
    return {
      loading: false,
      items: [],
      searchText: '',
      select: null,
      cuentas: [
        {
          id: 1,
          numero: '4620 3528 3456 8978',
          descripcion: '',
          saldo: 0.0,
          cierre: null,
          kilo_subsidiados: 450,
          kilo_disponibles: 450,
          cliente_id: 1,
          cliente: {
            id: 1,
            documento: '35283456',
            razon_social: 'Lopez Mauro Federico',
            inactivo: null,
            created_at: '2024-08-28 10:00:00',
            updated_at: '2024-08-28 10:00:00'
          },
          sucursal_id: 1,
          created_at: '2024-08-28 10:00:00',
          updated_at: '2024-08-28 10:00:00'
        },
        {
          id: 2,
          numero: '4620 4058 9449 4687',
          descripcion: '',
          saldo: 0.0,
          cierre: null,
          kilo_subsidiados: 450,
          kilo_disponibles: 200,
          cliente_id: 2,
          cliente: {
            id: 2,
            documento: '40589449',
            razon_social: 'Saravia Julieta',
            inactivo: null,
            created_at: '2024-08-28 10:00:00',
            updated_at: '2024-08-28 10:00:00'
          },
          sucursal_id: 1,
          created_at: '2024-08-28 10:00:00',
          updated_at: '2024-08-28 10:00:00'
        },
        {
          id: 3,
          numero: '4620 2895 4684 3598',
          descripcion: 'Casa de atras',
          saldo: 0.0,
          cierre: null,
          kilo_subsidiados: 450,
          kilo_disponibles: 70,
          cliente_id: 3,
          cliente: {
            id: 3,
            documento: '28954684',
            razon_social: 'Gonzalez Jose',
            inactivo: null,
            created_at: '2024-08-28 10:00:00',
            updated_at: '2024-08-28 10:00:00'
          },
          sucursal_id: 1,
          created_at: '2024-08-28 10:00:00',
          updated_at: '2024-08-28 10:00:00'
        },
      ]
    }
  },
  beforeMount () {
    // Agrega el event listener en el gancho beforeMount
    window.addEventListener('keydown', this.handleKeyboard);
  },
  beforeDestroy () {
    // Remueve el event listener en el gancho beforeDestroy
    window.removeEventListener('keydown', this.handleKeyboard);
  },
  created () {
    // if (this.$route.params.id) {
    //   this.$store.dispatch('Clientes/onFind', this.$route.params.id);
    // }
  },
  computed: {
    ...mapGetters('Cuentas', ['edited', 'selected']),
    // edited: {
    //   get () {
    //     return this.$store.getters['Clientes/edited']
    //   },
    //   set (value) {
    //     this.$store.commit('Clientes/edited', value);
    //   }
    // },
    // domicilioEdited: {
    //   get () {
    //     return this.$store.getters['Domicilios/edited']
    //   },
    //   set (value) {
    //     this.$store.commit('Domicilios/edited', value);
    //   }
    // },
    // isSelected () {
    //   return this.edited.id > 0
    // },
    // clienteId () {
    //   return this.edited.id
    // }
  },
  methods: {
    ...mapMutations('Cuentas', ['setEdited', 'setSelected']),
    ...mapMutations('Clientes', { setEditedCliente: 'setEdited', setSelectedCliente: 'setSelected' }),
    onMenuBuscar (event) {
      event.preventDefault()
      alert('Buscar Cuenta Coincidente!')
    },
    handleKeyboard(event) {
      if(event.key === "F8" && !event.ctrlKey) {
        this.onMenuBuscar(event)
      }
    },
    querySelections (v) {
      this.loading = true
      // Simulated ajax query
      setTimeout(() => {
        this.items = this.cuentas.filter(e => {
          return ((e.cliente.documento + ' ' + e.cliente.razon_social + ' ' + e.numero) || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1
        })
        this.loading = false
      }, 500)
    },
    onSelect (val) {
      const selectedItem = this.cuentas.find(item => item.id === val);
      if (selectedItem) {
        this.setEdited(selectedItem);
        this.setSelected(selectedItem);
        this.setEditedCliente(selectedItem.cliente);
        this.setSelectedCliente(selectedItem.cliente);
        this.searchText = selectedItem.cliente.razon_social;  // Esto mantiene el nombre visible
      }
      // console.log(JSON.stringify(item))
      // if (
      //   item.id && 
      //   item.numero && 
      //   item.saldo && 
      //   item.cliente_id && 
      //   item.sucursal_id &&
      //   item.created_at &&
      //   item.updated_at
      // ) {
      //   alert()
      //   this.setEdited(item)
      //   this.setSelected(item)
      //   this.setEditedCliente(item.cliente)
      //   this.setSelectedCliente(item.cliente)
      // }
    }
  },
  watch: {
    select (val) {
      if (val) {
        const selectedItem = this.cuentas.find(item => item.id === val);
        if (selectedItem) {
          this.setEdited(selectedItem);
          this.setSelected(selectedItem);
          this.setEditedCliente(selectedItem.cliente);
          this.setSelectedCliente(selectedItem.cliente);
          this.searchText = selectedItem.cliente.razon_social; // Esto asegura que el nombre permanezca visible
        }
      }
    },
    // selected (val) {
    //   if (val && val.id == null) {
    //       this.onClearEdited();
    //       this.onClearSelected();
    //       this.onClearEditedCliente();
    //       this.onClearSelectedCliente();
    //       this.searchText = '';
    //   }
    // }
  },
}
</script>
