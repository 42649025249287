const URL = '/cuentas';

const state = {
  all: [],
  selected: {
    id: null,
    documento: null,
    razon_social: null,
    numero: null,
    descripcion: null,
    saldo: null,
    cierre: null,
    kilos_subsidiados: null,
    kilos_disponibles: null,
    cliente_id: null,
    created_id: null,
    updated_id: null
  },
  edited: {
    id: null,
    numero: null,
    descripcion: null,
    saldo: null,
    cierre: null,
    kilos_subsidiados: null,
    kilos_disponibles: null,
    cliente_id: null,
    created_id: null,
    updated_id: null
  },
  default: {
    id: null,
    numero: null,
    descripcion: null,
    saldo: null,
    cierre: null,
    kilos_subsidiados: null,
    kilos_disponibles: null,
    cliente_id: null,
    created_id: null,
    updated_id: null
  },
  filters: {
    searchText: null,
    numero: null,
    descripcion: null,
    cierre_from: null,
    cierre_to: null,
    cliente_id: null,
    // cliente
    documento: null,
    razon_social: null,
    inactivo: null
  },
  loading: false,
  headers: [
    {
      text: 'Número',
      value: 'numero',
      align: 'center'
    },
    {
      text: 'Descripcion',
      value: 'descripcion',
      align: 'center'
    },
    {
      text: 'Subsidiados',
      value: 'kilos_subsidiados',
      align: 'center'
    },
    {
      text: 'Disponibles',
      value: 'kilos_disponibles',
      align: 'center'
    },
    {
      text: 'Fecha Cierre',
      value: 'cierre',
      align: 'center'
    },
  ],
  rules: {
    numero: [
      v => !!v || 'Este campo es necesario',
    ],
    descripcion: [
      // v => !!v || 'Este campo es necesario',
    ],
    saldo: [
      // v => !!v || 'Este campo es necesario',
    ],
    cierre: [
      // v => !!v || 'Este campo es necesario',
    ],
    cliente_id: [
      v => !!v || 'Este campo es necesario',
      v => v > 0 || 'Debe existir un cliente asociado',
    ],
    sucursal_id: [
      v => !!v || 'Este campo es necesario',
      v => v > 0 || 'Debe existir una sucursal asociada',
    ],
  },
  options: {
    itemsPerPage: 10,
    page: 1,
    sortBy: [],
    sortDesc: []
  },
  itemsLength: null,
}

// GETTERS
const getters = {
  all (state) {
    return state.all
  },
  edited (state) {
    return state.edited
  },
  selected (state) {
    return state.selected
  },
  selected (state) {
    return state.selected
  },
  selectedId (state) {
    return state.selected && state.selected.id > 0 ? state.selected.id : null
  },
  filters (state) {
    return state.filters
  },
  rules (state) {
    return state.rules
  },
  headers (state) {
    return state.headers
  },
  itemsLength (state) {
    return state.itemsLength
  },
  options (state) {
    return state.options
  },
  loading (state) {
    return state.loading
  },
  defaultable (state) {
    return state.edited.id === null &&
      state.edited.numero === null &&
      state.edited.descripcion === null &&
      state.edited.saldo === null &&
      state.edited.cierre === null &&
      state.edited.kilos_subsidiados === null &&
      state.edited.kilos_disponibles === null
  },
  updatable (state) {
    return state.edited.id == state.selected.id && (
      state.edited.numero != state.selected.numero ||
      state.edited.descripcion != state.selected.descripcion ||
      state.edited.saldo != state.selected.saldo ||
      state.edited.cierre != state.selected.cierre ||
      state.edited.kilos_disponibles != state.selected.kilos_disponibles
    )
  },
}

// ACTIONS
const actions = {
  onClearAll ({ commit }) {
    commit('setAll', [])
    commit('setOptions', {
      itemsPerPage: 10,
      page: 1,
      sortBy: [],
      sortDesc: []
    })
  },
  onClearEdited ({ state, commit}) {
    commit('setEdited', state.default)
  },
  onClearSelected ({ state, commit}) {
    commit('setSelected', state.default)
  },
  onFetch ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.get(URL, {
        params: {
          itemsPerPage: state.options.itemsPerPage,
          page: state.options.page,
          sortBy: state.options.sortBy[0],
          sortDesc: state.options.sortDesc[0] ? 1 : 0,
          searchText: state.filters.searchText,
          numero: state.filters.numero,
          documento: state.filters.documento,
          razon_social: state.filters.razon_social,
          inactivo: state.filters.inactivo,
          cliente_id: state.filters.cliente_id,
        }
      })
        .then(response => {
          commit('setAll', response.data.data)
          commit('setItemsLength', response.data.meta.total)
          if (state.options.page + state.options.itemsPerPage > response.data.meta.total)
            commit('setPage', 1)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onRefresh ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.get(URL + '/' + state.edited.id)
        .then(response => {
          /** Se guarda como objecto la información de la cuenta en una variable auxiliar */
          const aux = {
            ...state.edited,
            numero: response.data.numero,
            descripcion: response.data.descripcion,
            saldo: response.data.saldo,
            saldo: response.data.saldo,
            cierre: response.data.cierre,
            kilos_subsidiados: response.data.kilos_subsidiados,
            kilos_disponibles: response.data.kilos_disponibles,
            created_at: response.data.created_at,
            updated_at: response.data.updated_at
          }
          /** Se guarda la información en edited y selected del store Cuentas */
          commit('setEdited', aux)
          commit('setSelected', aux)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onSave ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.post(URL, state.edited)
        .then(response => {
          const aux = {
            id: response.data.data.id,
            numero: response.data.data.numero,
            descripcion: response.data.data.descripcion,
            saldo: response.data.data.saldo,
            cierre: response.data.data.cierre,
            cliente_id: response.data.data.cliente_id,
            sucursal_id: response.data.data.sucursal_id,
            created_at: response.data.data.created_at,
            updated_at: response.data.data.updated_at
          }
          commit('setEdited', aux)
          commit('setSelected', aux)
          commit('ResponseHTTP/addSuccess', 'La cuenta fue registrada', { root: true })
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error. No se registro la cuenta', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onUpdate ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.put(URL + '/' + state.edited.id, state.edited)
        .then(response => {
          commit('setSelected', state.edited)
          commit('ResponseHTTP/addSuccess', 'La cuenta fue actualizada.', { root: true })
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error. No se actualizo la cuenta.', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onDelete ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      axios.delete(URL + '/' + state.edited.id)
        .then(response => {
          commit('ResponseHTTP/addSuccess', 'La cuenta fue borrada.', { root: true })
          commit('setEdited', state.default)
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error. No se borro la cuenta.', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
}

// MUTATIONS
const mutations = {
  setAll (state, payload) {
    state.all = payload.slice()
  },
  setEdited (state, payload) {
    state.edited = Object.assign({}, payload)
  },
  setSelected (state, payload) {
    state.selected = Object.assign({}, payload)
  },
  setFilters (state, payload) {
    state.filters = Object.assign({}, payload)
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setPage (state, payload) {
    state.options.page = payload
  },
  setItemsPerPage (state, payload) {
    state.options.itemsPerPage = payload
  },
  setItemsLength (state, payload) {
    state.itemsLength = payload
  },
  setOptions (state, payload) {
    state.options = payload
  },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
