<template>
  <div>
    <v-navigation-drawer
      permanent
      app
    >
      <v-toolbar class="primary">
      </v-toolbar>
      <v-list nav>
        <v-list-item>
          <v-list-item-icon>
          </v-list-item-icon>
          <v-list-item-title>Datos del cliente</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
          </v-list-item-icon>
          <v-list-item-title>Cuentas Activas</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-credit-card-check</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
          </v-list-item-icon>
          <v-list-item-title>Cuentas Cerradas</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-credit-card-remove</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <!-- DATOS CLIENTE -->
    <clientes-edit />

    <!-- CUENTAS ACTIVAS -->
    <cuentas-activas-datatable />
    <!-- CUENTAS CERRADAS -->
    <cuentas-cerradas-datatable />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import CuentasActivasDatatable from '@/pages/cuentas/ActivasComponent'
import CuentasCerradasDatatable from '@/pages/cuentas/CerradasComponent'
import ClientesEdit from '@/pages/clientes/EditComponent'

export default {
  components: {
    'cuentas-activas-datatable': CuentasActivasDatatable,
    'cuentas-cerradas-datatable': CuentasCerradasDatatable,
    'clientes-edit': ClientesEdit
  },
  data () {
    return {
      valid: true,
      attrs: {
        // class: 'mb-6',
        // height: '500px',
        boilerplate: true,
        elevation: 2,
      },
    }
  },
  beforeDestroy () {
    // /* clientes store */
    // this.$store.dispatch('Clientes/onClearEdited');
    // this.$store.dispatch('Clientes/onClearSelected');
    // /* domicilios store */
    // this.$store.dispatch('Domicilios/onClearEdited');
    // this.$store.dispatch('Domicilios/onClearSelected');
    // /* comodatos store */
    // this.$store.dispatch('Comodatos/onClearAll');
  },
  created () {
    if (this.$route.params.id) {
      this.$store.dispatch('Clientes/onFind', this.$route.params.id);
    }
  },
  computed: {
    edited: {
      get () {
        return this.$store.getters['Clientes/edited']
      },
      set (value) {
        this.$store.commit('Clientes/edited', value);
      }
    },
    domicilioEdited: {
      get () {
        return this.$store.getters['Domicilios/edited']
      },
      set (value) {
        this.$store.commit('Domicilios/edited', value);
      }
    },
    isSelected () {
      return this.edited.id > 0
    },
    clienteId () {
      return this.edited.id
    }
  },
  watch: {
    //
  },
  methods: {
    //
  },
}
</script>
