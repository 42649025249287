import Vue from 'vue'
import Router from 'vue-router'
// // LAYOUTS
import MainLayout from '@/pages/layouts/Main'
import Login from '@/pages/Login'
import Forbidden from '@/pages/Forbidden'
import Unauthorized from '@/pages/Unauthorized'
import ServiciosMain from '@/pages/servicios/Main'
import CredencialesSesion from '@/pages/sesion/Credenciales'
import PermisosSesion from '@/pages/sesion/Permisos'
// FACTURAS
import FacturasMain from '@/pages/facturas/Main'
// TICKETS
import TicketsMain from '@/pages/tickets/Main'
// CLIENTES
import ClientesMain from '@/pages/clientes/Main'
// CUENTAS
import CuentasMain from '@/pages/cuentas/Main'
import ErrorsHttp from '@/pages/Errors'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/unauthorized',
      name: 'unauthorized',
      component: Unauthorized,
    },
    {
      path: '/',
      name: 'layout',
      component: MainLayout,
      redirect: 'cuentas/main',
      children: [
        // ####-CUENTAS-####
        {
          path: 'cuentas/main',
          name: 'cuentas-main',
          components: {
            default: CuentasMain
          },
          meta: {
            requiresAuth: true,
            permission: 'cuentas.index',
            title: 'Cuenta'
          }
        },
        // ####-CLIENTES-####
        {
          path: 'clientes/main',
          name: 'clientes-main',
          components: {
            default: ClientesMain
          },
          meta: {
            requiresAuth: true,
            permission: 'clientes.index',
            title: 'Clientes'
          }
        },
        // ####-FACTURAS-####
        {
          path: 'facturas/main',
          name: 'facturas-main',
          components: {
            default: FacturasMain
          },
          meta: {
            requiresAuth: true,
            permission: 'facturas.index',
            title: 'Facturacion'
          }
        },
        // ####-SERVICIOS-####
        {
          path: 'servicios/main',
          name: 'servicios-main',
          components: {
            default: ServiciosMain
          },
          meta: {
            requiresAuth: true,
            permission: 'servicios.index',
            title: 'Servicios'
          }
        },
        // ####-####-####-####
        {
          path: 'sesion/credenciales',
          name: 'sesion-credenciales',
          components: {
            default: CredencialesSesion,
          },
          meta: {
            requiresAuth: true,
            title: 'Credenciales',
            color: 'blue'
          }
        },
        {
          path: 'sesion/permisos',
          name: 'sesion-permisos',
          components: {
            default: PermisosSesion,
          },
          meta: {
            requiresAuth: true,
            title: 'Permisos',
            color: 'blue'
          }
        },
        // ####-TICKETS-####
        {
          path: 'tickets/main',
          name: 'tickets-main',
          components: {
            default: TicketsMain
          },
          meta: {
            requiresAuth: true,
            permission: 'debitos.index',
            title: 'Tickets'
          }
        },
        {
          path: '/forbidden',
          name: 'forbidden',
          component: Forbidden,
        },
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (!to.meta.permission) next()
  else {
    const token = window.sessionStorage.getItem('token')
    const permissions = JSON.parse(window.sessionStorage.getItem('permissions') || '[]')
    if (permissions.indexOf(to.meta.permission) !== -1) next()
    else if (!token) next({ name: 'login' })
    else next({ name: 'forbidden' })
  }
})

export default router;
