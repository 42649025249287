<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="450px"
      transition="dialog-transition"
    >
      <v-sheet>
        <v-toolbar dark class="primary">
          <v-btn
            icon
            @click="onClose"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Datos de la cuenta</v-toolbar-title>
        </v-toolbar>
        <v-form
          ref="form"
          v-model="valid"      
        >
          <v-container>
            <v-text-field
              v-model="numero"
              placeholder="Ingrese el número de cuenta"
              label="Número de cuenta"
              :rules="rules.numero"
              hint="sugerido 4620 3528 3456 9888"
              prepend-icon="mdi-card-account-details"
              color="primary"
            ></v-text-field>
            <v-text-field
              v-model="descripcion"
              placeholder="Ingrese descripcion de la cuenta"
              label="Descripcion"
              :rules="rules.descripcion"
              prepend-icon="mdi-text"
              color="primary"
            ></v-text-field>
            <v-text-field
              value="0.0"
              readonly
              label="Saldo"
              prepend-icon="mdi-currency-usd"
              color="primary"
            ></v-text-field>
            <v-text-field
              v-model="kiloSubsidiados"
              type="number"
              placeholder="Ingrese los kg subsidiados"
              label="KG subsidiados"
              :rules="rules.kilo_subsidiados"
              prepend-icon="mdi-weight-kilogram"
              color="primary"
            ></v-text-field>
            <v-text-field
              :value="kiloSubsidiados"
              readonly
              label="KG disponibles"
              prepend-icon="mdi-weight-kilogram"
              color="primary"
            ></v-text-field>
            <v-footer>
              <v-alert
                border="left"
                colored-border
                type="warning"
              >
                A continuación, vamos a crear una nueva cuenta para el cliente.
              </v-alert>
              <v-btn
                :disabled="!updatable"
                color="primary"
                @click="onSubmit"
                :loading="loading"
              >
                Confirmar
              </v-btn>
            </v-footer>
          </v-container>
        </v-form>
      </v-sheet>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    selected: Boolean
  },
  data () {
    return {
      valid: false,
    }
  },
  // beforeMount () {
  //   // Agrega el event listener en el gancho beforeMount
  //   window.addEventListener('keydown', this.handleKeyboard);
  // },
  // beforeDestroy () {
  //   // Remueve el event listener en el gancho beforeDestroy
  //   window.removeEventListener('keydown', this.handleKeyboard);
  //   this.onClearEdited()
  //   this.onClearSelected()
  // },
  created () {
    //
  },
  computed: {
    ...mapGetters('Cuentas', ['edited', 'rules', 'loading', 'updatable']),
    id () {
      return this.edited.id
    },
    dialog: {
      get () {
        return this.selected
      },
      set (value) {
        this.$emit('change', value)
      }
    },
    numero: {
      get () {
        return this.edited.numero
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          numero: value
        })
      }
    },
    descripcion: {
      get () {
        return this.edited.descripcion
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          descripcion: value
        })
      }
    },
    saldo () {
      return this.edited.saldo
    },
    kiloSubsidiados: {
      get () {
        return this.edited.kilo_subsidiados
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          kilo_subsidiados: value
        })
      }
    },    
    kiloDisponibles: {
      get () {
        return this.edited.kilo_disponibles
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          kilo_disponibles: value
        })
      }
    },    
    switchCierre: {
      get () {
        return this.edited.kilo_disponibles
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          kilo_disponibles: value
        })
      }
    },
  },
  watch: {
    dialog (val) {
      if (!val) {
        // Remueve el event listener en el gancho beforeDestroy
        window.removeEventListener('keydown', this.handleKeyboard);
        this.onClearEdited()
        this.onClearSelected()
      }
      else {
        // Agrega el event listener en el gancho beforeMount
        window.addEventListener('keydown', this.handleKeyboard);
      }

    }
  },
  methods: {
    ...mapActions('Cuentas', ['onSave', 'onFind', 'onClearEdited', 'onClearSelected']),
    ...mapMutations('Cuentas', ['setEdited']),
    onSubmit (event) {
      event.preventDefault()
      this.$refs.form.validate()
      if (this.valid) {
        this.onSave().then(response => {
          this.$emit('change', false)
        })
      }
    },
    onClose (event) {
      event.preventDefault()
      this.dialog = false
    },
    handleKeyboard(event) {
      if(event.key === "Enter" && !event.ctrlKey) {
        this.onSubmit(event)
      }
    }
  },
}
</script>
