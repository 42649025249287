<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="all"
      :footer-props="{
        itemsPerPageText: 'Filas por página'
      }"
      :loading="loading"
      :options.sync="optionsLocal"
      :server-items-length="itemsLength"
      mobile-breakpoint="1000"
      dense
      disable-sort
      @click:row="onSelect"
    >
      <template v-slot:top>
        <v-toolbar flat>
            <v-toolbar-title>Servicios</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
                @click="onDialogCreate"
            >
                <v-icon left>mdi-plus</v-icon>
                Nuevo
            </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.inactivo="{ item }">
        <div v-if="item.inactivo">
          {{ item.inactivo | moment('DD/MM/YYYY') }}
        </div>
      </template>
      <template v-slot:no-results>
        <v-alert color="error" icon="mdi-alert">
          No se ha encontrado ningún resultado para "{{ search }}".
        </v-alert>
      </template>
      <template v-slot:no-data>
        <v-alert color="warning" icon="mdi-alert">
          No hay información de servicios para mostrar.
        </v-alert>
      </template>
      <template v-slot:pageText="props">
        Filas {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
      </template>
    </v-data-table>
    <servicios-create
        v-model="dialogCreate"
    ></servicios-create>
    <servicios-edit
        v-model="dialogEdit"
    ></servicios-edit>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import ServiciosCreate from '@/pages/servicios/CreateComponent'
import ServiciosEdit from '@/pages/servicios/EditComponent'
export default {
  name: 'ServiciosDatatableComponent',
  components: {
    'servicios-create': ServiciosCreate,
    'servicios-edit': ServiciosEdit
  },
  data () {
    return {
      search: '',
      dialogCreate: false,
      dialogEdit: false,
    }
  },
  beforeMount () {
    // Agrega el event listener en el gancho beforeMount
    // window.addEventListener('keydown', this.handleKeyboard);
  },
  beforeDestroy () {
    // Remueve el event listener en el gancho beforeDestroy
    // window.removeEventListener('keydown', this.handleKeyboard);
  },
  created () {
    // this.onFetch()
  },
  computed: {
    ...mapGetters('Servicios', ['all', 'headers', 'loading', 'itemsLength', 'options', 'filters']),
    optionsLocal: {
      get () {
        return this.options
      },
      set (value) {
        this.setOptions(value)
        this.onFetch()
      }
    },
  },
  methods: {
    ...mapActions('Servicios', ['onFetch']),
    ...mapMutations('Servicios', ['setOptions', 'setEdited', 'setSelected']),
    onDialogCreate (event) {
        event.preventDefault()
        this.dialogCreate = true
    },
    onSelect (item) {
      /* Se cargan los datos en la variable Seleccionado para comparar */
      const aux = {
        ...this.edited,
        id: item.id,
        nombre: item.nombre,
        tecla: item.tecla,
        descripcion: item.descripcion,
        created_at: item.created_at,
        updated_at: item.updated_at
      }
      this.setEdited(aux)
      this.setSelected(aux)
      this.dialogEdit = true
    },
  }
}
</script>
