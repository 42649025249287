<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="all"
      :search="search"
      :footer-props="{
        itemsPerPageText: 'Filas por página'
      }"
      :loading="loading"
      :options.sync="options"
      :server-items-length="itemsLength"
      disable-sort
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-subheader>Cuentas Activas</v-subheader>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="onNuevaCuenta"
          >
            <v-icon left>mdi-plus</v-icon>
            Nueva cuenta
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.saldo="{ item }">
        <div v-if="item.saldo >= 0 ">
          $ {{ item.saldo }}
        </div>
      </template>
      <template v-slot:no-results>
        <v-alert color="error" icon="mdi-alert">
          No se ha encontrado ningún resultado para "{{ search }}".
        </v-alert>
      </template>
      <template v-slot:no-data>
        <v-alert color="warning" icon="mdi-alert">
          No hay información de cuentas para mostrar.
        </v-alert>
      </template>
      <template v-slot:pageText="props">
        Filas {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
      </template>
    </v-data-table>
    <cuentas-create v-model="dialogCreate" />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import DateInput from '@/components/DateInput'
import CuentasCreate from '@/pages/cuentas/CreateComponent'
export default {
  components: {
    'my-date-picker': DateInput,
    'cuentas-create': CuentasCreate,
  },
  data () {
    return {
      search: '',
      dialogCreate: false,
    }
  },
  beforeMount () {
    // Agrega el event listener en el gancho beforeMount
    window.addEventListener('keydown', this.handleKeyboard);
  },
  beforeDestroy () {
    // Remueve el event listener en el gancho beforeDestroy
    window.removeEventListener('keydown', this.handleKeyboard);
  },
  created () {
    // this.onFetch()
  },
  computed: {
    ...mapGetters('CuentasActivas', ['all', 'headers', 'loading', 'itemsLength', 'options', 'filters']),
    options: {
      get () {
        return this.optionsGetter
      },
      set (value) {
        this.setOptions(value)
        // this.onFetch()
      }
    },
    searchText: {
      get () {
        return this.filters.searchText
      },
      set (value) {
        this.setFilters({
          ...this.filters,
          searchText: value
        })
      }
    },
  },
  methods: {
    ...mapActions('CuentasActivas', ['onFetch', 'onClearFilters']),
    ...mapMutations('CuentasActivas', ['setFilters', 'setOptions', 'setEdited', 'setSelected']),
    onClear () {
      this.setFilters({
        ...this.filters,
        searchText: null
      })
      // this.onFetch()
    },
    onSubmitEdit (clienteId) {
      this.$router.push({ name: 'clientes-form', params: { id: clienteId }})
    },
    onNuevaCuenta (event) {
      event.preventDefault()
      this.dialogCreate = true
    },
    handleKeyboard(event) {
      // if(event.key === "F1" && !event.ctrlKey) {
      //   event.preventDefault()
      //   this.$router.push({ name: 'comodatos-form' })
      // }
      // event.preventDefault()
    }
  }
}
</script>
