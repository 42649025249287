<template>
  <div>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>
          Datos del cliente
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form
            ref="form"
            v-model="valid"      
          >
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="documento"
                  placeholder="Ingrese un número de documento"
                  label="Número de documento"
                  :rules="rules.documento"
                  prepend-icon="mdi-card-account-details"
                  color="primary"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="razonSocial"
                  placeholder="Ingrese apellido y nombre"
                  label="Apellido y nombre"
                  :rules="rules.razon_social"
                  prepend-icon="mdi-text"
                  color="primary"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-switch
                  v-model="inactivo"
                  label="Inactivo. Cierra las cuentas activas y se oculta en las búsquedas."
                  color="primary"
                ></v-switch>
              </v-col>
            </v-row>
            <v-footer>
              <v-btn
                :disabled="!updatable"
                color="primary"
                @click="onSubmit"
                :loading="loading"
              >
                Actualizar
              </v-btn>
            </v-footer>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    selected: Number
  },
  data () {
    return {
      valid: false,
    }
  },
  beforeDestroy () {
    this.onClearEdited()
    this.onClearSelected()
  },
  created () {
    const aux = {
      id: 3,
      documento: '28954684',
      razon_social: 'Gonzalez Jose',
      inactivo: null,
      ADM_cliente_id: null,
      DIS_cliente_id: null,
      created_at: '2024-08-28 10:00:00',
      updated_at: '2024-08-28 10:00:00'
    }
    this.setEdited(aux)
    this.setSelected(aux)
  },
  computed: {
    ...mapGetters('Clientes', ['edited', 'rules', 'loading', 'updatable']),
    id () {
      return this.edited.id
    },
    documento: {
      get () {
        return this.edited.documento
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          documento: value
        })
      }
    },
    razonSocial: {
      get () {
        return this.edited.razon_social
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          razon_social: value
        })
      }
    },
    inactivo: {
      get () {
        return this.edited.inactivo ? true : false
      },
      set (value) {
        const now = new Date()
        var inactivoAux = null
        if (value === true) inactivoAux = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        this.setEdited({
          ...this.edited,
          inactivo: inactivoAux
        })
      }
    },
  },
  methods: {
    ...mapActions('Clientes', ['onUpdate', 'onFind', 'onClearEdited', 'onClearSelected']),
    ...mapMutations('Clientes', ['setEdited', 'setSelected']),
    onSubmit () {
      this.$refs.form.validate()
      if (this.valid) {
        this.onUpdate().then(response => {
          this.$emit('change', this.edited.id)
        })
      }
    }
  },
}
</script>
