<template>
  <div>
    <v-navigation-drawer
      permanent
      app
    >
      <v-toolbar class="primary"></v-toolbar>
      <v-list
        nav
      >
        <v-list-item>
          <v-list-item-title>Facturas emitidas</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-check</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item>
          <v-list-item-title>Facturas pendientes</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-clock-outline</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item>
          <v-list-item-title>Facturas anuladas</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-close</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item
          @click=""
        >
          <v-list-item-title>Configuracion</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-tools</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-expansion-panels
      v-model="panel"
      multiple
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          Facturas emitidas
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <facturas-emitidas />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          Facturas pendientes
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <facturas-pendientes />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          Facturas anuladas
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <facturas-anuladas />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!-- <facturas-listener /> -->
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import FacturasEmitidas from '@/pages/facturas_emitidas/DatatableComponent'
import FacturasPendientes from '@/pages/facturas_pendientes/DatatableComponent'
import FacturasAnuladas from '@/pages/facturas_anuladas/DatatableComponent'

export default {
  components: {
    'facturas-emitidas': FacturasEmitidas,
    'facturas-pendientes': FacturasPendientes,
    'facturas-anuladas': FacturasAnuladas
  },
  /**
    * Facturación por lote
    * La facturación mensual se realiza por cada debito el total de débitos con CARGO GAS SUBSIDIADO bonos entregados por el cliente y las cargas realizadas dentro del periodo comprendido desde el 29 de cada mes hasta el 5 del siguiente mes. 
    * Además, puede tener otra factura por el “excedente” de kilos de gas cargados con CARGO GAS SUBSIDIADO; en esos casos los kilos mencionados se cobran como PAGO GAS PLANTA.
      Octubre 28 [29/09, 28/10]
      Noviembre 28 [29/10, 28/11]
      Diciembre 28 [29/11, 28/12]
      Enero 28 [29/12, 28/01]
      Febrero 28 [29/01, 28/02]
      Marzo 28 [29/02|01/03, 28/03]
      Abril 28 [29/03, 28/04] 
      Mayo 28 [29/04, 28/05] 
      Junio 28 [29/05, 28/06] 
      Julio 28 [29/06, 28/07] 
      Agosto 28 [29/07, 28/08] 
      Septiembre 28 [29/08, 28/09]
   */
  data () {
    return {
      valid: true,
      attrs: {
        // class: 'mb-6',
        // height: '500px',
        boilerplate: true,
        elevation: 2,
      },
      dialogCreate: false,
      panel: [1],
    }
  },
  beforeDestroy () {
    //
  },
  created () {
    //
  },
  computed: {
    //
  },
  watch: {
    //
  },
  methods: {
    onNuevoCliente (event) {
      event.preventDefault()
      this.dialogCreate = true
    }
  },
}
</script>
