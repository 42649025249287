<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="all"
      :footer-props="{
        itemsPerPageText: 'Filas por página',
        itemsPerPageOptions: [10,15,30,50,100]
      }"
      :loading="loading"
      :options.sync="optionsLocal"
      :server-items-length="itemsLength"
      :items-per-page="10"
      disable-sort
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-subheader>Transacciones de la cuenta</v-subheader>
          <v-spacer></v-spacer>
          <v-btn
            class="success"
          >
            <v-icon left>mdi-file-excel-box</v-icon>
            Exportar
          </v-btn>
          <v-btn
            class="primary"
          >
            <v-icon left>mdi-refresh</v-icon>
            Actualizar
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.id="{ item }">
        <div v-if="item.transaccion_tipo && item.transaccion_tipo === 'Debito'">
          <v-icon color="error">mdi-circle</v-icon>
        </div>
        <div v-else-if="item.transaccion_tipo && item.transaccion_tipo === 'Credito'">
          <v-icon color="success">mdi-circle</v-icon>
        </div>
      </template>
      <template v-slot:item.fecha="{ item }">
        <div v-if="item.fecha">
          {{ item.fecha | moment('DD/MM/YYYY') }}
        </div>
      </template>
      <template v-slot:item.transaccion_detalle="{ item }">
        <div v-if="item.transaccion_detalle">
          {{ item.transaccion_detalle }}
        </div>
      </template>
      <!--
      <template v-slot:item.importe="{ item }">
        <div v-if="item.importe">
        {{ formatImporte(item) }}
        </div>
      </template>
      <template v-slot:item.saldo="{ item }">
        $ {{ item.saldo }}
      </template>
      -->
      <template v-slot:no-data>
        <v-alert :value="true" color="warning" icon="mdi-alert">
          No hay información de transacciones para mostrar.
        </v-alert>
      </template>
      <template v-slot:pageText="props">
        Filas {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  data () {
    return {
      //
    }
  },
  created () {
    //
  },
  computed: {
    ...mapGetters('Transacciones', ['all', 'headers', 'loading', 'itemsLength', 'options']),
    optionsLocal: {
      get () {
        return this.options
      },
      set (value) {
        this.setOptions(value)
        // this.onFetch()
      }
    },
  },
  methods: {
    ...mapActions('Transacciones', ['onFetch']),
    ...mapMutations('Transacciones', ['setOptions']),
    // formatImporte (item) {
    //   return item.transaccion_tipo === 'Debito' ? `-${item.importe}` : `+${item.importe}`;
    // }
  }
}
/**
*
  transaccion: {
    id: null,
    fecha: null,
    importe: null,
    saldo: null,
    transaccion_tipo: null, // debito o credito
    transaccion_detalle: null, // Nombre del servicio o forma de pago
    cuenta_id: null,
    // TRANSACCION KILO
    kilo_cantidad: null,
    kilo_precio: null,
    kilo_saldo: null,
    created_at: null,
    updated_at: null
  }
  headers: [
    {
      text: 'Fecha',
      value: 'fecha',
      align: 'center'
    },
    {
      text: 'Detalle',
      value: 'transaccion_detalle',
      align: 'center'
    },
    {
      text: 'Debito',
      value: 'transaccion_tipo o importe',
      align: 'center'
    },
    {
      text: 'Credito',
      value: 'transaccion_tipo o importe',
      align: 'center'
    },
    {
      text: 'Saldo',
      value: 'saldo',
      align: 'center'
    },
  ]
*/
</script>
