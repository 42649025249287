var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.all,"footer-props":{
      itemsPerPageText: 'Filas por página',
      itemsPerPageOptions: [10,15,30,50,100]
    },"loading":_vm.loading,"options":_vm.optionsLocal,"server-items-length":_vm.itemsLength,"items-per-page":10,"disable-sort":""},on:{"update:options":function($event){_vm.optionsLocal=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-subheader',[_vm._v("Transacciones de la cuenta")]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{staticClass:"success"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-file-excel-box")]),_vm._v("\n          Exportar\n        ")],1),_vm._v(" "),_c('v-btn',{staticClass:"primary"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-refresh")]),_vm._v("\n          Actualizar\n        ")],1)],1)]},proxy:true},{key:"item.id",fn:function(ref){
    var item = ref.item;
return [(item.transaccion_tipo && item.transaccion_tipo === 'Debito')?_c('div',[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-circle")])],1):(item.transaccion_tipo && item.transaccion_tipo === 'Credito')?_c('div',[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-circle")])],1):_vm._e()]}},{key:"item.fecha",fn:function(ref){
    var item = ref.item;
return [(item.fecha)?_c('div',[_vm._v("\n        "+_vm._s(_vm._f("moment")(item.fecha,'DD/MM/YYYY'))+"\n      ")]):_vm._e()]}},{key:"item.transaccion_detalle",fn:function(ref){
    var item = ref.item;
return [(item.transaccion_detalle)?_c('div',[_vm._v("\n        "+_vm._s(item.transaccion_detalle)+"\n      ")]):_vm._e()]}},{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"warning","icon":"mdi-alert"}},[_vm._v("\n        No hay información de transacciones para mostrar.\n      ")])]},proxy:true},{key:"pageText",fn:function(props){return [_vm._v("\n      Filas "+_vm._s(props.pageStart)+" - "+_vm._s(props.pageStop)+" de "+_vm._s(props.itemsLength)+"\n    ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }