<template>
  <div>    
    <v-select
      label="Servicios"
      v-model="selectedLocal"
      :item-text="(item) => { return item.tecla + ' ' + item.nombre}"
      item-value="id"
      :items="all"
    >
      <template v-slot:selection="{ item, index }">
        <v-chip :key="index">
          <span>{{ item.nombre }}</span>
        </v-chip>
      </template>
    </v-select>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  name: 'ServiciosSelectComponent',
  model: {
    prop: 'selected',
    event: 'change'
  },
  components: {
    //
  },
  props: {
    selected: {
      type: Number,
      default: null
    },
    dialog: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      loading: false,
      all: [
        {
          id: 1,
          tecla: '1',
          nombre: 'CARGO GAS SUBSIDIADO',
          precio: 1568.0,
        },
        {
          id: 2,
          tecla: '2',
          nombre: 'CARGO GAS PLANTA',
          precio: 1600.0,
        }
      ],
    }
  },
  created () {
    // Agrega el event listener en el gancho beforeMount
    window.addEventListener('keydown', this.handleKeyboard);
  },
  beforeDestroy () {
    // Remueve el event listener en el gancho beforeDestroy
    window.removeEventListener('keydown', this.handleKeyboard);
  },
  computed: {
    selectedLocal: {
      get () {
        return this.selected
      },
      set (value) {
        this.$emit('change', value)
      }
    }
  },
  watch: {
    //
  },
  methods: {
    handleKeyboard (event) {
      // Verifica si Ctrl está presionado y si la tecla es un número del 0 al 9
      if (event.ctrlKey && /^[0-9]$/.test(event.key)) {
        const selectedItem = this.all.find(item => item.tecla === event.key);
        if (selectedItem) {
          event.preventDefault();
          this.selectedLocal = selectedItem.id
        }
      }
    },
    itemText (item) {
      return '[' + item.tecla + '] ' + item.nombre
    }
  }
}
</script>
