<template>
  <div>
    <v-sheet>
      <v-container>
        <v-subheader>Carga de tickets</v-subheader>
        <v-skeleton-loader
          v-bind="attrs"
          type="table"
        ></v-skeleton-loader>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  components: {
    //
  },
  data () {
    return {
      attrs: {
        // class: 'mb-6',
        // height: '500px',
        boilerplate: true,
        elevation: 2,
      },
    }
  },
  beforeDestroy () {
    //
  },
  created () {
    //
  },
  computed: {
    //
  },
  watch: {
    //
  },
  methods: {
    //
  },
}
</script>
