<template>
  <div>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>
          Filtros
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="documento"
                label="Documento"
                type="number"
                clearable
                prepend-icon="mdi-card-account-details"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="razonSocial"
                label="Razón social"
                clearable
                prepend-icon="mdi-text"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-switch
                v-model="switchInactivo"
                label="Tambien los inactivos"
              ></v-switch>
            </v-col>
          </v-row>
          <v-footer>
            <v-btn
              color="primary"
              :disabled="!filterable"
            >
              Aplicar filtros
            </v-btn>
            <v-spacer></v-spacer>
            
            <v-btn
              color="primary"
            >
              Quitar filtros
            </v-btn>
          </v-footer>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  data () {
    return {
      //
    }
  },
  beforeMount () {
    // Agrega el event listener en el gancho beforeMount
    // window.addEventListener('keydown', this.handleKeyboard);
  },
  beforeDestroy () {
    // Remueve el event listener en el gancho beforeDestroy
    // window.removeEventListener('keydown', this.handleKeyboard);
  },
  created () {
    //
  },
  computed: {
    ...mapGetters('Clientes', ['loading', 'itemsLength', 'filters', 'options', 'sort', 'filterable']),
    switchInactivo: {
      get () {
        return this.filters.inactivos
      },
      set (value) {
        this.setFilters({
          ...this.filters,
          inactivos: value
        })
      }
    },
    sortBy: {
      get () {
        return this.sort.by
      },
      set (value) {
        this.setSortBy(value)
        this.onFetch()
      }
    },
    sortDesc: {
      get () {
        return this.sort.desc
      },
      set (value) {
        this.setSortDesc(value)
        this.onFetch()
      }
    },
    documento: {
      get () {
        return this.filters.documento
      },
      set (value) {
        this.setFilters({
          ...this.filters,
          documento: value
        })
      }
    },
    razonSocial: {
      get () {
        return this.filters.razon_social
      },
      set (value) {
        this.setFilters({
          ...this.filters,
          razon_social: value
        })
      }
    },
  },
  methods: {
    ...mapActions('Clientes', ['onClearFilters', 'onFetch']),
    ...mapMutations('Clientes', ['setFilters', 'setOptions', 'setPage', 'setSortBy', 'setSortDesc']),
    handleKeyboard(event) {
      // if(event.key === "F1" && !event.ctrlKey) {
      //   event.preventDefault()
      //   // this.$router.push({ name: 'comodatos-form' })
      // }
      // else if(event.key === "F2" && !event.ctrlKey) {
      //   event.preventDefault()
      //   // this.$router.push({ name: 'clientes-form' })
      // }
      // else if(event.key === "F5" && !event.ctrlKey) {
      //   event.preventDefault()
      //   this.onFetch()
      // }
      // event.preventDefault()
    }
  }
}
</script>
