<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="400px"
      transition="dialog-transition"
    >
      <v-sheet>
        <v-toolbar dark class="primary">
          <v-btn
            icon
            @click="onClose"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Editar servicio</v-toolbar-title>
        </v-toolbar>
        <v-form
          ref="form"
          v-model="valid"      
        >
          <v-container>
            <v-text-field
              :value="nombre"
              label="Nombre"
              prepend-icon="mdi-text"
              color="primary"
              readonly
            ></v-text-field>
            <v-text-field
              v-model="tecla"
              label="Tecla (Alt+)"
              prepend-icon="mdi-keyboard"
              color="primary"
            ></v-text-field>
            <v-text-field
              :value="descripcion"
              label="Descripcion"
              prepend-icon="mdi-text"
              color="primary"
              readonly
            ></v-text-field>
            <v-text-field
              :value="precio"
              label="Precio"
              type="number"
              prepend-icon="mdi-currency-usd"
              color="primary"
              readonly
            ></v-text-field>
            <v-footer>
              <v-btn
                :disabled="!updatable"
                color="primary"
                @click="onSubmit"
                :loading="loading"
              >
                Actualizar
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="!$can('servicios.destroy')"
                color="error"
                @click="onDelete"
                :loading="loading"
              >
                Eliminar
              </v-btn>
            </v-footer>
          </v-container>
        </v-form>
      </v-sheet>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  name: 'ServiciosCreateComponent',
  components: {
    //
  },
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    selected: Boolean
  },
  data () {
    return {
      valid: false,
    }
  },
  mounted () {
    // Agrega el event listener en el gancho beforeMount
    window.addEventListener('keydown', this.handleKeyboard);
  },
  beforeDestroy () {
    // Remueve el event listener en el gancho beforeDestroy
    window.removeEventListener('keydown', this.handleKeyboard);
    this.onClearEdited()
    this.onClearSelected()
  },
  created () {
    //
  },
  computed: {
    ...mapGetters('Servicios', ['edited', 'rules', 'loading', 'updatable']),
    dialog: {
      get () {
        return this.selected
      },
      set (value) {
        this.$emit('change', value)
      }
    },
    nombre: {
      get () {
        return this.edited.nombre
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          nombre: value
        })
      }
    },
    tecla: {
      get () {
        return this.edited.tecla
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          tecla: value
        })
      }
    },
    descripcion: {
      get () {
        return this.edited.descripcion
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          descripcion: value
        })
      }
    },
    precio: {
      get () {
        return this.edited.precio
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          precio: value
        })
      }
    },
  },
  watch: {
    dialog (val) {
      if (!val) {
        // Remueve el event listener en el gancho beforeDestroy
        window.removeEventListener('keydown', this.handleKeyboard);
        this.onClearEdited()
        this.onClearSelected()
      }
      else {
        // Agrega el event listener en el gancho beforeMount
        window.addEventListener('keydown', this.handleKeyboard);
      }
    }
  },
  methods: {
    ...mapActions('Servicios', ['onUpdate', 'onClearEdited', 'onClearSelected', 'onFetch']),
    ...mapMutations('Servicios', ['setEdited']),
    onSubmit (event) {
      event.preventDefault()
      this.$refs.form.validate()
      if (this.valid) {
        this.onUpdate().then(response => {
          this.onFetch()
          this.dialog = false
        })
      }
    },
    onClose (event) {
      event.preventDefault()
      this.dialog = false
    },
    onDelete (event) {
      event.preventDefault()
    },
    handleKeyboard(event) {
      if(event.key === "Enter" && !event.ctrlKey) {
        this.onSubmit(event)
      }
    }
  },
}
</script>
