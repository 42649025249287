<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="400px"
      transition="dialog-transition"
    >
      <v-sheet>
        <v-toolbar dark class="primary">
          <v-btn
            icon
            @click="onClose"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Datos del cliente</v-toolbar-title>
        </v-toolbar>
        <v-form
          ref="form"
          v-model="valid"      
        >
          <v-container>
            <v-text-field
              v-model="documento"
              placeholder="Ingrese un número de documento"
              label="Número de documento"
              :rules="rules.documento"
              prepend-icon="mdi-card-account-details"
              color="primary"
            ></v-text-field>
            <v-text-field
              v-model="razonSocial"
              placeholder="Ingrese apellido y nombre"
              label="Apellido y nombre"
              :rules="rules.razon_social"
              prepend-icon="mdi-text"
              color="primary"
            ></v-text-field>
            <v-footer>
              <v-btn
                :disabled="!updatable"
                color="primary"
                @click="onSubmit"
                :loading="loading"
              >
                Registrar
              </v-btn>
            </v-footer>
          </v-container>
        </v-form>
      </v-sheet>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  name: 'CreateComponent',
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    selected: Boolean
  },
  data () {
    return {
      valid: false,
    }
  },
  beforeDestroy () {
    this.onClearEdited()
    this.onClearSelected()
  },
  created () {
    //
  },
  computed: {
    ...mapGetters('Clientes', ['edited', 'rules', 'loading', 'updatable']),
    id () {
      return this.edited.id
    },
    dialog: {
      get () {
        return this.selected
      },
      set (value) {
        this.$emit('change', value)
      }
    },
    documento: {
      get () {
        return this.edited.documento
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          documento: value
        })
      }
    },
    razonSocial: {
      get () {
        return this.edited.razon_social
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          razon_social: value
        })
      }
    },
  },
  methods: {
    ...mapActions('Clientes', ['onSave', 'onClearEdited', 'onClearSelected']),
    ...mapMutations('Clientes', ['setEdited']),
    onSubmit () {
      this.$refs.form.validate()
      if (this.valid) {
        this.onSave().then(response => {
          this.$emit('change', this.edited.id)
        })
      }
    },
    onClose (event) {
      event.preventDefault()
      this.dialog = false
    }
  },
}
</script>
