<template>
  <div>
    <!-- CUENTA VIEW/EDIT -->
    <cuenta-navigation-drawer />
    <!-- CUENTA SELECTOR -->
    <cuentas-select
      v-if="defaultable"
    ></cuentas-select>
    <cuentas-selected
      v-else
    ></cuentas-selected>
    <v-divider class="py-1"></v-divider>
    <!--
      * menu buscar / menu cambiar
      * razon social
      * menu usuario
    -->

    <!-- FILTROS -->
    <transacciones-filtros />
    <v-divider></v-divider>
    <!-- 
      * Fecha desde
      * Fecha hasta
      * Kilos
      * Formas de pago
      * servicios
     -->

    <!-- TRANSACCIONES -->
    <transacciones-datatable />
    <v-divider></v-divider>

    <!-- TAREAS -->
    <tareas-datatable />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import CuentaNavigationDrawer from '@/pages/cuentas/CuentaNavigationDrawer'
import TransaccionesDatatable from '@/pages/transacciones/Datatable'
import TransaccionesFilters from '@/pages/transacciones/Filters'
import CuentasSelect from '@/pages/cuentas/SelectComponent'
import CuentasSelected from '@/pages/cuentas/SelectedComponent'
import TareasDatatable from '@/pages/tareas/Datatable'

export default {
  components: {
    'cuenta-navigation-drawer': CuentaNavigationDrawer,
    'cuentas-select': CuentasSelect,
    'cuentas-selected': CuentasSelected,
    'transacciones-datatable': TransaccionesDatatable,
    'transacciones-filtros': TransaccionesFilters,
    'tareas-datatable': TareasDatatable,
  },
  data () {
    return {
      valid: true,
      attrs: {
        boilerplate: true,
        elevation: 2,
      },
    }
  },
  beforeDestroy () {
    /* clientes store */
    // this.$store.dispatch('Clientes/onClearEdited');
    // this.$store.dispatch('Clientes/onClearSelected');
    /* domicilios store */
    // this.$store.dispatch('Domicilios/onClearEdited');
    // this.$store.dispatch('Domicilios/onClearSelected');
    /* comodatos store */
    // this.$store.dispatch('Comodatos/onClearAll');
  },
  created () {
    // if (this.$route.params.id) {
    //   this.$store.dispatch('Clientes/onFind', this.$route.params.id);
    // }
  },
  computed: {
    ...mapGetters('Cuentas', ['defaultable']),
    edited: {
      get () {
        return this.$store.getters['Clientes/edited']
      },
      set (value) {
        this.$store.commit('Clientes/edited', value);
      }
    },
    domicilioEdited: {
      get () {
        return this.$store.getters['Domicilios/edited']
      },
      set (value) {
        this.$store.commit('Domicilios/edited', value);
      }
    },
    isSelected () {
      return this.edited.id > 0
    },
    clienteId () {
      return this.edited.id
    }
  },
  watch: {
    //
  },
  methods: {
    //
  },
}
</script>
