import Vue from 'vue'
import Router from 'vue-router'
// // LAYOUTS
import MainLayout from '@/pages/layouts/Main'
import Login from '@/pages/Login'
import Forbidden from '@/pages/Forbidden'
import Unauthorized from '@/pages/Unauthorized'
import CredencialesSesion from '@/pages/sesion/Credenciales'
import PermisosSesion from '@/pages/sesion/Permisos'
// CLIENTES
import ClientesMain from '@/pages/clientes/Main'
import ClientesForm from '@/pages/clientes/Form'
// CUENTAS
import CuentasMain from '@/pages/cuentas/Main'
import ErrorsHttp from '@/pages/Errors'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/unauthorized',
      name: 'unauthorized',
      component: Unauthorized,
    },
    {
      path: '/',
      name: 'layout',
      component: MainLayout,
      redirect: 'cuentas/main',
      children: [
        // ####-CUENTAS-####
        {
          path: 'cuentas/main',
          name: 'cuentas-main',
          components: {
            default: CuentasMain
          },
          meta: {
            requiresAuth: true,
            permission: 'cuentas.index',
            title: 'Cuenta'
          }
        },
        // ####-CLIENTES-####
        {
          path: 'clientes/main',
          name: 'clientes-main',
          components: {
            default: ClientesMain
          },
          meta: {
            requiresAuth: true,
            permission: 'clientes.index',
            title: 'Clientes'
          }
        },
        {
          path: 'clientes/form',
          name: 'clientes-form',
          components: {
            default: ClientesForm
          },
          meta: {
            requiresAuth: true,
            permission: 'clientes.index',
            title: 'Clientes Form'
          }
        },
        // ####-####-####-####
        {
          path: 'sesion/credenciales',
          name: 'sesion-credenciales',
          components: {
            default: CredencialesSesion,
          },
          meta: {
            requiresAuth: true,
            title: 'Credenciales',
            color: 'blue'
          }
        },
        {
          path: 'sesion/permisos',
          name: 'sesion-permisos',
          components: {
            default: PermisosSesion,
          },
          meta: {
            requiresAuth: true,
            title: 'Permisos',
            color: 'blue'
          }
        },
        {
          path: '/forbidden',
          name: 'forbidden',
          component: Forbidden,
        },
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (!to.meta.permission) next()
  else {
    let accessToken = window.sessionStorage.getItem('access_token')
    let permissions = JSON.parse(window.sessionStorage.getItem('permissions') || '[]')
    if (permissions.indexOf(to.meta.permission) !== -1) next()
    else if (!accessToken) next({ name: 'login' })
    else next({ name: 'forbidden' })
  }
})

export default router;
