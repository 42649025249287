<template>
  <div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  name: 'FacturasPendientesListenerComponent',
  data () {
    return {
      //
    }
  },
  created () {
    // Escuchar el evento a nivel global usando $root.$on
    this.$root.$on('update:sucursal_id', this.onUpdateSucursalId)
  },
  beforeDestroy () {
    // Eliminar el listener cuando el componente se destruya
    this.$root.$off('update:sucursal_id', this.onUpdateSucursalId)
  },
  computed: {
  },
  watch: {
  },
  methods: {
    ...mapActions('FacturasPendientes', ['onFetch']),
    onUpdateSucursalId (value) {
      // Lógica para manejar la actualización de sucursal_id
      this.onFetch() // Aquí puedes hacer la lógica que desees como la llamada a onFetch
    }
  }
}
</script>
