<template>
  <div>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>
          Filtros
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="fechaDesde"
                label="Fecha desde"
                clearable
                prepend-icon="mdi-calendar"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="fechaHasta"
                label="Fecha hasta"
                clearable
                prepend-icon="mdi-calendar"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="kilos"
                label="Kilos"
                clearable
                prepend-icon="mdi-weight-kilogram"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <servicios-select/>
            </v-col>
            <v-col cols="12" md="6">
              <formas-pagos-select/>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import ServiciosSelect from '@/pages/servicios/SelectMultipleComponent'
import FormasPagosSelect from '@/pages/formas_pagos/SelectMultipleComponent'
export default {
  components: {
    'servicios-select': ServiciosSelect,
    'formas-pagos-select': FormasPagosSelect,
  },
  data () {
    return {
      //
    }
  },
  beforeMount () {
    // Agrega el event listener en el gancho beforeMount
    window.addEventListener('keydown', this.handleKeyboard);
  },
  beforeDestroy () {
    // Remueve el event listener en el gancho beforeDestroy
    window.removeEventListener('keydown', this.handleKeyboard);
  },
  created () {
    //
  },
  computed: {
    ...mapGetters('Transacciones', ['loading', 'itemsLength', 'filters', 'options', 'sort']),
    sortBy: {
      get () {
        return this.sort.by
      },
      set (value) {
        this.setSortBy(value)
        this.onFetch()
      }
    },
    sortDesc: {
      get () {
        return this.sort.desc
      },
      set (value) {
        this.setSortDesc(value)
        this.onFetch()
      }
    },
    fechaDesde: {
      get () {
        return this.filters.fecha_from
      },
      set (value) {
        this.setFilters({
          ...this.filters,
          fecha_from: value
        })
      }
    },
    fechaHasta: {
      get () {
        return this.filters.fecha_to
      },
      set (value) {
        this.setFilters({
          ...this.filters,
          fecha_to: value
        })
      }
    },
    kilos: {
      get () {
        return this.filters.kilos
      },
      set (value) {
        this.setFilters({
          ...this.filters,
          kilos: value
        })
      }
    },
    servicios: {
      get () {
        return this.filters.servicios
      },
      set (value) {
        this.setFilters({
          ...this.filters,
          servicios: value
        })
      }
    },
    formasPagos: {
      get () {
        return this.filters.formas_pago
      },
      set (value) {
        this.setFilters({
          ...this.filters,
          formas_pago: value
        })
      }
    },
  },
  methods: {
    ...mapActions('Transacciones', ['onClearFilters', 'onFetch']),
    ...mapMutations('Transacciones', ['setFilters', 'setOptions', 'setPage', 'setSortBy', 'setSortDesc']),
    handleKeyboard(event) {
      // if(event.key === "F1" && !event.ctrlKey) {
      //   event.preventDefault()
      //   // this.$router.push({ name: 'comodatos-form' })
      // }
      // else if(event.key === "F2" && !event.ctrlKey) {
      //   event.preventDefault()
      //   // this.$router.push({ name: 'clientes-form' })
      // }
      // else if(event.key === "F5" && !event.ctrlKey) {
      //   event.preventDefault()
      //   this.onFetch()
      // }
      // event.preventDefault()
    }
  }
}
</script>
