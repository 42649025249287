<template>
  <div>
    <v-navigation-drawer
      permanent
      app
    >
      <v-toolbar class="primary">
      </v-toolbar>
      <v-list
        nav
      >
        <v-list-item
          dark
          class="primary"
          @click="onNuevoCliente"
        >
          <v-list-item-icon>
            <v-icon>mdi-keyboard-f1</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Nuevo cliente</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-plus</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
          </v-list-item-icon>
          <v-list-item-title>Filtros</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-filter-variant</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
          </v-list-item-icon>
          <v-list-item-title>Exportaciones</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-export</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!-- CREATE -->
    <clientes-create
      v-model="dialogCreate"
    />
    <!--
      * menu buscar / menu cambiar
      * razon social
      * menu usuario
    -->

    <!-- FILTROS -->
    <clientes-filtros />
    <!-- 
      * Fecha desde
      * Fecha hasta
      * Kilos
      * Formas de pago
      * servicios
     -->
    <!-- CLIENTES -->
    <clientes-datatable />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import ClientesFilters from '@/pages/clientes/Filters'
import ClientesDatatable from '@/pages/clientes/Datatable'
import ClientesCreate from '@/pages/clientes/CreateComponent'

export default {
  components: {
    'clientes-filtros': ClientesFilters,
    'clientes-datatable': ClientesDatatable,
    'clientes-create': ClientesCreate,
  },
  data () {
    return {
      valid: true,
      attrs: {
        // class: 'mb-6',
        // height: '500px',
        boilerplate: true,
        elevation: 2,
      },
      dialogCreate: false,
    }
  },
  beforeDestroy () {
    //
  },
  created () {
    //
  },
  computed: {
    //
  },
  watch: {
    //
  },
  methods: {
    onNuevoCliente (event) {
      event.preventDefault()
      this.dialogCreate = true
    }
  },
}
</script>
