import Vue from 'vue'
import Vuex from 'vuex'

import Adjuntos from '@/store/modules/Adjuntos'
import Auth from '@/store/modules/Auth'
import Clientes from '@/store/modules/Clientes'
import Creditos from '@/store/modules/Creditos'
import Cuentas from '@/store/modules/Cuentas'
import CuentasActivas from '@/store/modules/CuentasActivas'
import CuentasCerradas from '@/store/modules/CuentasCerradas'
import CuentasKilos from '@/store/modules/CuentasKilos'
import Debitos from '@/store/modules/Debitos'
import Facturas from '@/store/modules/Facturas'
import FormasPagos from '@/store/modules/FormasPagos'
import Precios from '@/store/modules/Precios'
import Transacciones from '@/store/modules/Transacciones'
import TransaccionesKilos from '@/store/modules/TransaccionesKilos'

import ResponseHTTP from '@/store/modules/ResponseHTTP'
// import createLogger from '../../../src/plugins/logger'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    Adjuntos,
    Auth,
    Clientes,
    Creditos,
    Cuentas,
    CuentasActivas,
    CuentasCerradas,
    CuentasKilos,
    Debitos,
    Facturas,
    FormasPagos,
    Precios,
    Transacciones,
    TransaccionesKilos,
    ResponseHTTP,
  },
  // strict: debug,
  // plugins: debug ? [createLogger()] : []
})