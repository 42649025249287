<template>
  <div>
    <v-toolbar
      dark
      color="primary"
      v-if="conversor"
    >
      <v-text-field
        type="number"
        v-model="litros"
        :disabled="disabled"
        placeholder="xxx,xx"
      >
        <template v-slot:prepend>
          <span>Conversor</span>
        </template>
        <template v-slot:append-outer>
          <span>Litros</span>
        </template>
      </v-text-field>
    </v-toolbar>

    <v-text-field
      ref="textField"
      v-model="formateado"
      prepend-icon="mdi-gauge"
      label="Gas Granel (Kg)"
      :disabled="disabled"
      placeholder="Ingrese cantidad de kilos (xxx.xxx,xxx)"
      @change="onChange"
      @keydown="onKeydown"
      @blur="onBlur"
    ></v-text-field>
  </div>
</template>

<script>
export default {
  name: 'KilosInputComponent',
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    selected: {
      type: Number,
      default: 0.0,
    },
    rules: Array,
    focus: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    conversor: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      litros: null,
      formateado: null
    }
  },
  computed: {
    selectedLocal: {
      get () {
        return this.selected
      },
      set (value) {
        this.$emit('change', value)
      }
    },
  },
  watch: {
    litros (val) {
      if (val && val > 0) {
        const kilos = parseFloat(val) * 0.51;
        this.onChange(kilos)
      }
      else {
        // alert()
        this.formateado = '0,000'
      }
    },
    focus (newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.$refs.textField.focus();
        });
      }
    },
    selectedLocal (newValue) {
      //
      this.formateado = this.formatearNumero(newValue);
    },
  },
  methods: {
    onChange (event) {
      const inputValue = event;
      if (inputValue && inputValue > 0) {
        const formateadoAux = parseFloat(inputValue);
        if (!isNaN(formateadoAux)) {
          this.selectedLocal = formateadoAux;
        }
      }
    },
    onBlur (event) {
      event.preventDefault();
      if (this.formateado && this.formateado.length > 0) {
        const formateadoAux = parseFloat(this.formateado.replace(',', '.')); // Reemplaza comas por puntos
        if (!isNaN(formateadoAux)) {
          this.selectedLocal = formateadoAux;
        }
      }
    },
    onKeydown (event) {
      const key = event.key;
      const isDigit = key >= '0' && key <= '9';
      const isCommaOrDot = key === ',' || key === '.';
      const isControlKey = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(key); // Teclas de control permitidas
      // Obtén el valor actual del campo de texto
      const currentValue = event.target.value;
      // Verifica si ya hay un punto o una coma en el texto
      const alreadyHasDecimal = currentValue.includes(',') || currentValue.includes('.');
      // Si ya hay un punto o una coma y el usuario intenta ingresar otro, prevenir el evento
      if (isCommaOrDot && alreadyHasDecimal) {
        event.preventDefault();
      }
      // Si no es un dígito, coma/punto permitido o una tecla de control, prevenir el evento
      if (!isDigit && !isCommaOrDot && !isControlKey) {
        event.preventDefault();
      }
    },
    formatearNumero (value) {
      // Asegúrate de que el valor sea un número
      const numero = parseFloat(value);
      if (isNaN(numero)) return ''; // Si no es un número, retorna una cadena vacía
      const partes = numero.toFixed(3).split('.');
      partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Agregar puntos como separadores de miles
      return partes.join(',');
    },
    onActualizar (inputValue) {
      // Expresión regular que permite solo números enteros o decimales con hasta 3 dígitos después del punto
      const regex = /^\d+([,.]\d{0,3})?$/;
      // Reemplaza comas por puntos para que parseFloat funcione correctamente
      const valorConPunto = inputValue.replace(',', '.');
      // Verifica que el valor cumpla con la expresión regular
      if (regex.test(valorConPunto)) {
        this.formateado = valorConPunto; // Actualiza el valor formateado
        this.selectedLocal = parseFloat(valorConPunto); // Convierte el valor a número
      } else {
        // Si el valor no es válido, puedes limpiar el campo o mostrar un mensaje (opcional)
        console.log('Entrada inválida: solo se permiten números decimales con hasta 3 dígitos decimales');
      }
    },
  }
}
</script>
