<template>
  <div>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>
          Notas
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-alert
            border="left"
            colored-border
            type="info"
            elevation="1"
          >
            Aca va una tabla con las anotaciones registradas para la cuenta.
            Por ejemplo, un usuario puede registrar un recordatorio sobre la situación de bonos del cliente.
          </v-alert>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  components: {
    //
  },
  data () {
    return {
      //
    }
  },
  beforeMount () {
    //
  },
  beforeDestroy () {
    //
  },
  created () {
    //
  },
  computed: {
    //
  },
  methods: {
    handleKeyboard(event) {
      // if(event.key === "F1" && !event.ctrlKey) {
      //   event.preventDefault()
      // }
    }
  }
}
</script>
