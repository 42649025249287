<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="400px"
      transition="dialog-transition"
    >
      <v-sheet>
        <v-toolbar dark class="primary">
          <v-btn
            icon
            @click="onClose"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Datos del credito</v-toolbar-title>
        </v-toolbar>
        <v-form
          ref="form"
          v-model="valid"      
        >
          <v-container>
            <formas-pagos-select
              v-if="dialog"
              v-model="formaPagoId"
            ></formas-pagos-select>
            <v-text-field
              :value="fecha | moment('DD/MM/YYYY')"
              readonly
              label="Fecha"
              prepend-icon="mdi-calendar"
              color="primary"
            ></v-text-field>
            <!-- KILO CANTIDAD -->
            <v-text-field
              v-model="kiloCantidad"
              placeholder="Ingrese cantidad de kilos"
              label="Gas envasado"
              type="number"
              :rules="rules.kilo_cantidad"
              prepend-icon="mdi-weight-kilogram"
              color="primary"
            ></v-text-field>
            <v-text-field
              v-model="importe"
              placeholder="Ingrese importe de la transaccion"
              label="Importe"
              type="number"
              :rules="rules.importe"
              prepend-icon="mdi-currency-usd"
              color="primary"
              hint="sugerido $450.600"
            ></v-text-field>
            <v-footer>
              <v-btn
                :disabled="!updatable"
                color="primary"
                @click="onSubmit"
                :loading="loading"
              >
                Registrar
              </v-btn>
            </v-footer>
          </v-container>
        </v-form>
      </v-sheet>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import FormasPagosSelect from '@/pages/formas_pagos/SelectComponent'
export default {
  name: 'CreditosCreateComponent',
  components: {
    'formas-pagos-select': FormasPagosSelect
  },
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    selected: Boolean
  },
  data () {
    return {
      valid: false,
    }
  },
  mounted () {
    // Agrega el event listener en el gancho beforeMount
    window.addEventListener('keydown', this.handleKeyboard);
  },
  beforeDestroy () {
    // Remueve el event listener en el gancho beforeDestroy
    window.removeEventListener('keydown', this.handleKeyboard);
    this.onClearEdited()
    this.onClearSelected()
  },
  created () {
    //
  },
  computed: {
    ...mapGetters('Creditos', ['edited', 'rules', 'loading', 'updatable']),
    id () {
      return this.edited.id
    },
    dialog: {
      get () {
        return this.selected
      },
      set (value) {
        this.$emit('change', value)
      }
    },
    fecha () {
      return this.edited.fecha
    },
    importe: {
      get () {
        return this.edited.importe
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          importe: value
        })
      }
    },
    kiloCantidad: {
      get () {
        return this.edited.kilo_cantidad
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          kilo_cantidad: value
        })
      }
    },
    cuentaId: {
      get () {
        return this.edited.cuenta_id
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          cuenta_id: value
        })
      }
    },
    formaPagoId: {
      get () {
        return this.edited.forma_pago_id
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          forma_pago_id: value
        })
      }
    },
  },
  watch: {
    //
  },
  methods: {
    ...mapActions('Creditos', ['onSave', 'onClearEdited', 'onClearSelected']),
    ...mapMutations('Creditos', ['setEdited']),
    onSubmit (event) {
      event.preventDefault()
      this.$refs.form.validate()
      if (this.valid) {
        this.onSave().then(response => {
          this.$emit('change', this.edited.id)
        })
      }
    },
    onClose (event) {
      event.preventDefault()
      this.dialog = false
    },
    handleKeyboard(event) {
      if(event.key === "Enter" && !event.ctrlKey) {
        this.onSubmit(event)
      }
    }
  },
}
</script>
