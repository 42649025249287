<template>
  <div>
    <v-select
      label="Formas de pago"
      v-model="formaPagoId"
      :item-text="(item) => { return item.tecla + '. ' + item.nombre  + ' $ ' + item.precio }"
      item-value="id"
      :items="all"
      prepend-icon="mdi-cash-register"
      @change="onChangeSelect"
    >
      <template v-slot:selection="{ item, index }">
        <v-chip :key="index">
          <span>{{ item.tecla + '. ' + item.nombre + ' $ ' + item.precio }}</span>
        </v-chip>
      </template>
    </v-select>
    <v-list-item v-if="complementoLocal.id > 0">
      <v-list-item-action>
        <v-checkbox
          :disabled="!(formaPagoId > 0)"
          v-model="checkbox"
        ></v-checkbox>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>{{ complementoLocal.nombre + ' $ ' + complementoLocal.precio }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  name: 'FormasPagosSelectComponent',
  model: {
    prop: 'formapagoid',
    event: 'change'
  },
  components: {
    //
  },
  props: {
    formapagoid: {
      type: Number,
      default: null
    },
    cantidad: {
      type: Number,
      default: 0.0
    },
    dialog: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      checkbox: true,
      loading: false,
    }
  },
  created () {
    // Agrega el event listener en el gancho beforeMount
    window.addEventListener('keydown', this.handleKeyboard);
    this.onFetch()
  },
  beforeDestroy () {
    // Remueve el event listener en el gancho beforeDestroy
    window.removeEventListener('keydown', this.handleKeyboard);
  },
  computed: {
    ...mapGetters('FormasPagos', ['all', 'selected', 'complemento']),
    formaPagoId: {
      get () {
        return this.formapagoid
      },
      set (value) {
        this.$emit('change', value)
      }
    },
    selectedLocal: {
      get () {
        return this.selected
      },
      set (value) {
        this.setSelected(value)
      }
    },
    complementoLocal: {
      get () {
        return this.complemento
      },
      set (value) {
        this.setComplemento(value)
      }
    }
  },
  watch: {
    cantidad (val) {
      this.updateImporte();
    },
    checkbox (newVal) {
      this.$emit('update:complemento', newVal ? this.complementoLocal.id : null)
      this.updateImporte();
    },
  },
  methods: {
    ...mapActions('FormasPagos', ['onFetch', 'onClearComplemento']),
    ...mapMutations('FormasPagos', ['setSelected', 'setComplemento']),
    onChangeSelect (id) {
      const selectedItem = this.all.find(item => item.id === id);
      // Se encuentra la forma de pago seleccionada y tiene forma de pago complementaria
      if (selectedItem && selectedItem.forma_pago_id > 0) {
        const complementoItem = this.all.find(item => item.id === selectedItem.forma_pago_id);
        this.complementoLocal = complementoItem;
        this.checkbox = true
      }
      // Si no tiene forma de pago complementaria, limpia variables
      else {
        this.checkbox = false
        this.onClearComplemento()
      }
      // Se encuentra la forma de pago seleccionada
      if (selectedItem && selectedItem.id > 0) {
        this.selectedLocal = selectedItem;
        this.formaPagoId = selectedItem.id
        this.$emit('update:kilos', selectedItem.kilos)
        // this.updateImporte();
      }
    },
    updateImporte () {
      let importe = 0.0;
      const cantidad = this.cantidad || 0.0;
      const usarComplemento = this.checkbox;
      let aux = [{
        forma_pago_id: this.formaPagoId,
        importe: cantidad * parseFloat(this.selectedLocal.precio)
      }];
      if (usarComplemento) {
        aux.push({
          forma_pago_id: this.complementoLocal.id,
          importe: cantidad * parseFloat(this.complementoLocal.precio)
        });
      }
      console.log(JSON.stringify(aux))
      // Suma los importes de todos los elementos en aux
      const importeTotal = aux.reduce((total, item) => total + item.importe, 0.0);
      // console.log(importeTotal)
      this.$emit('update:pagos', aux);
      this.$emit('update:importe', importeTotal);
    },
    handleKeyboard (event) {
      if(event.altKey && event.key == 's' && this.complementoLocal.id > 0) {
        event.preventDefault()
        this.checkbox = !this.checkbox
      }
      // if(event.altKey && event.key == 'i') {
      //   event.preventDefault()
      //   this.checkbox = !this.checkbox
      // }
      // Verifica si Alt está presionado y si la tecla es un número del 0 al 9
      else if (event.altKey && /^[0-9]$/.test(event.key)) {
        const selectedItem = this.all.find(item => item.tecla === event.key);
        if (selectedItem) {
          event.preventDefault();
          this.onChangeSelect(selectedItem.id)
        }
      }
    }
  },
}
</script>
