<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="all"
      :footer-props="{
        itemsPerPageText: 'Filas por página'
      }"
      :loading="loading"
      :options.sync="optionsLocal"
      :server-items-length="itemsLength"
      disable-sort
    >
      <template v-slot:top>
        <v-subheader>Lista de clientes</v-subheader>
      </template>
      <template v-slot:item.inactivo="{ item }">
        <div v-if="item.inactivo">
          {{ item.inactivo | moment('DD/MM/YYYY') }}
        </div>
      </template>
      <template v-slot:no-results>
        <v-alert color="error" icon="mdi-alert">
          No se ha encontrado ningún resultado para "{{ search }}".
        </v-alert>
      </template>
      <template v-slot:no-data>
        <v-alert color="warning" icon="mdi-alert">
          No hay información de clientes para mostrar.
        </v-alert>
      </template>
      <template v-slot:pageText="props">
        Filas {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import DateInput from '@/components/DateInput'
export default {
  components: {
    'my-date-picker': DateInput,
  },
  data () {
    return {
      search: '',
      selected: [],
      showFilters: false,
      showOrdering: false,
      showExports: false,
    }
  },
  beforeMount () {
    // Agrega el event listener en el gancho beforeMount
    // window.addEventListener('keydown', this.handleKeyboard);
  },
  beforeDestroy () {
    // Remueve el event listener en el gancho beforeDestroy
    // window.removeEventListener('keydown', this.handleKeyboard);
  },
  created () {
    // this.onFetch()
  },
  computed: {
    ...mapGetters('Clientes', ['all', 'headers', 'loading', 'itemsLength', 'options', 'filters']),
    optionsLocal: {
      get () {
        return this.options
      },
      set (value) {
        this.setOptions(value)
        // this.onFetch()
      }
    },
    anySelectedFilters (value) {
      const obj = this.filters
      // Itera sobre las claves del objeto
      for (const key in obj) {
        // Verifica si el valor es distinto de null e undefined
        if (obj[key] !== null && obj[key] !== undefined) {
          // Si encuentra un valor que no es null ni undefined, retorna false
          return false
        }
      }
      // Si todos los valores son null o undefined, retorna true
      return true
    }
  },
  methods: {
    ...mapActions('Clientes', ['onFetch']),
    ...mapMutations('Clientes', ['setOptions']),
  }
}
</script>
