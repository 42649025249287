<template>
  <div>
    <v-navigation-drawer
      permanent
      app
      width="350"
    >
      <v-toolbar flat>
        <v-subheader>Datos de la cuenta</v-subheader>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="onCuentaEdit"
          :disabled="!cuentaSelected"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </v-toolbar>
      <v-list
        nav
      >
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-credit-card</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-subtitle>Número</v-list-item-subtitle>
            <v-list-item-title>{{ numero }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-text</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-subtitle>Descripción</v-list-item-subtitle>
            <v-list-item-title>{{ descripcion }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-currency-usd</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-subtitle>Saldo</v-list-item-subtitle>
            <v-list-item-title>{{ saldo }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-gauge-full</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-subtitle>KG subsidiados</v-list-item-subtitle>
            <v-list-item-title>{{ kilos_subsidiados }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-gauge</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-subtitle>KG disponibles</v-list-item-subtitle>
            <v-list-item-title>{{ kilos_disponibles }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon
              v-if="parseFloat(kilos_disponibles) < 0"
              class="error--text"
            >
              mdi-alert-circle-outline
            </v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list
        nav
      >
        <v-list-item
          :class="cuentaSelected ? 'primary' : 'grey'"
          dark
          @click="onNuevoDebito"
          :disabled="!cuentaSelected"
        >
          <v-list-item-icon>
            <v-icon>mdi-keyboard-f1</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Nuevo debito</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-plus</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item
          :class="cuentaSelected ? 'primary' : 'grey'"
          dark
          @click="onNuevoCredito"
          :disabled="!cuentaSelected"
        >
          <v-list-item-icon>
            <v-icon>mdi-keyboard-f2</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Nuevo credito</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-plus</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item
          @click="onImprimirSaldo"
        >
          <v-list-item-icon>
            <v-icon>mdi-keyboard-f6</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Imprimir saldo</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-printer</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item
          @click="onImprimirDeuda"
        >
          <v-list-item-icon>
            <v-icon>mdi-keyboard-f7</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Imprimir deuda</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-printer</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    
    <cuentas-edit v-model="dialogEditCuenta" />
    <debitos-create v-model="dialogCreateDebito" />
    <creditos-create v-model="dialogCreateCredito" />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import CuentasEdit from '@/pages/cuentas/EditComponent'
import DebitosCreate from '@/pages/debitos/CreateComponent'
import CreditosCreate from '@/pages/creditos/CreateComponent'

export default {
  components: {
    'cuentas-edit': CuentasEdit,
    'debitos-create': DebitosCreate,
    'creditos-create': CreditosCreate
  },
  data () {
    return {
      dialogEditCuenta: false,
      dialogCreateDebito: false,
      dialogCreateCredito: false
    }
  },
  beforeMount () {
    // Agrega el event listener en el gancho beforeMount
    window.addEventListener('keydown', this.handleKeyboard);
  },
  beforeDestroy () {
    // Remueve el event listener en el gancho beforeDestroy
    window.removeEventListener('keydown', this.handleKeyboard);
  },
  created () {
    // if (this.$route.params.id) {
    //   this.$store.dispatch('Clientes/onFind', this.$route.params.id);
    // }
  },
  computed: {
    ...mapGetters('Cuentas', ['selected']),
    cuentaSelected () {
      return this.selected && this.selected.id > 0 
    },
    numero () {
      return this.selected.numero
    },
    descripcion () {
      return this.selected.descripcion
    },
    saldo () {
      return this.selected.saldo
    },
    kilos_subsidiados () {
      return this.selected.kilos_subsidiados
    },
    kilos_disponibles () {
      return this.selected.kilos_disponibles
    },
    //   set (value) {
    //     this.set;
    //   }
    // },
    // domicilioEdited: {
    //   get () {
    //     return this.$store.getters['Domicilios/edited']
    //   },
    //   set (value) {
    //     this.$store.commit('Domicilios/edited', value);
    //   }
    // },
    // isSelected () {
    //   return this.edited.id > 0
    // },
    // clienteId () {
    //   return this.edited.id
    // }
  },
  watch: {
    //
  },
  methods: {
    onNuevoDebito (event) {
      event.preventDefault()
      this.dialogCreateDebito = true
    },
    onNuevoCredito (event) {
      event.preventDefault()
      this.dialogCreateCredito = true
    },
    onImprimirSaldo (event) {
      event.preventDefault()
      alert('Imprimir saldo!')
    },
    onImprimirDeuda (event) {
      event.preventDefault()
      alert('Imprimir deuda!')
    },
    onCuentaEdit (event) {
      event.preventDefault()
      this.dialogEditCuenta = true
    },
    handleKeyboard(event) {
      const actions = {
        F1: this.onNuevoDebito,
        F2: this.onNuevoCredito,
        F6: this.onImprimirSaldo,
        F7: this.onImprimirDeuda
      };

      if (!event.ctrlKey && this.cuentaSelected && actions[event.key]) {
        event.preventDefault()
        actions[event.key].call(this, event);
      }
      else if (!event.ctrlKey && !this.cuentaSelected && actions[event.key]) {
        event.preventDefault()
        alert('Primero debe seleccionar una cuenta!')
      }
    }
  },
}
</script>
